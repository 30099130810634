<!-- <button class="dialog__close" mat-dialog-close></button> -->

<mat-dialog-content>

  <div class="dialog__wrapper dialog__wrapper--flex">

    <h1 class="page__title mt20" i18n="@@dialog.lang-country.title">Join our panel</h1>

    <p class="page__intro page__intro--grey"
      i18n="@@dialog.lang-country.desc">
      Before creating your account on <strong>Explorer</strong>, please confirm your <strong>country</strong> and <strong>language</strong>. Thank you!
    </p>

    <ex-form-lang-country
      [lang]="lang"
      (submitted)="onSubmit($event)">
    </ex-form-lang-country>

  </div>
</mat-dialog-content>
