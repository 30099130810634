import { BreedMapper, CountryMapper, Images, JSONObject, Kind, Mapper, PetTypeMapper, Urls, authorParser } from "yummypets-js-core"
import { Pet } from "../domain/pet.model"

export class PetMapper implements Mapper<JSONObject, Pet> {
    mapFromEntity(json: JSONObject): Pet {
      
      let pet = <Pet>{} 
  
      try {
  
        pet = authorParser(json, pet)
        pet.kind = Kind.PET
        pet.urlPath = Urls.PET
        /**
         * json.type_id author from team 
         */
        const petType = json.pet_type || json.type || json.type_id
        if(petType) pet.petType = new PetTypeMapper().mapFromEntity(petType)
        if(json.breed) pet.breed = new BreedMapper().mapFromEntity(json.breed)
        if(json.country) pet.country = new CountryMapper().mapFromEntity(json.country)
  
        if(json.sexe) pet.sexe = json.sexe
        if(json.about) pet.about = json.about
        if(json.pseudo) pet.pseudo = json.pseudo
        if(json.avatar) pet.avatar = <Images>json.avatar
        if(json.user_id) pet.userId = json.user_id
        if(json.website) pet.website = json.website 
        if(json.pedigree) pet.pedigree = json.pedigree 
        if(json.description) pet.description = json.description
        if(json.born_date) pet.bornDate = json.born_date

        pet.follower = json.follower || 0
        pet.following = json.following || 0
        pet.protected = json.protected || false
        pet.messaging = json.messaging || false
        pet.current = json.current || false
        pet.isDead = json.memorial || false
        pet.isFamous = json.is_famous || false
        pet.blocked = json.blocked || false
        pet.blockedBy = json.blocked_by || false
        pet.isCrewMember = json.is_crew_member || false
  
        /**
         * @deprecated
         */
        if(json.about) {
          pet.favoritePlace = json.about.favorite_place || false
          pet.mistake = json.about.biggest_mistake || false
          pet.toy = json.about.favorite_toy || false
          pet.meal = json.about.favorite_meal || false
        }
  
        // pet.fetchUrl = `/pets/${pet.id}` 

  
      } catch (error) {
        console.warn(`Error on ${this.constructor.name}'s mapFromEntity function : `, error)
      }
  
      return pet
    }
  }