<nav class="navbar" [ngClass]="{ 'navbar--expanded': isMenuVisible }">

    <div class="wrapper navbar__container">

        <div class="navbar__mobile">

            <!-- LOGO -->
            <a class="navbar__logo" (click)="redirectToPage()">
                <img [src]="logoSVG" alt="Explorer Yummypets">
            </a>
            
            <!-- BUTTON BURGER -->
            <a  role="button"
                class="navbar__burger"
                [ngClass]="{ 'active': isMenuVisible}"
                data-target="navMenu"
                aria-label="menu"
                aria-expanded="false"
                (click)="toggleNav(isMenuVisible ? false : true)">
                <span></span>
                <span></span>
                <span></span>
            </a>
        </div>

        <div class="navbar__menu" #mobileHeader>
            <ex-navbar-links></ex-navbar-links>
            <ex-navbar-auth></ex-navbar-auth>
        </div>


    </div>

</nav>
