import { Injectable } from "@angular/core";
import { WindowReferenceService } from "./window.service";
import { NavigationEnd, Router } from "@angular/router";
import { environment } from "src/environments/environment";
import { Location } from "@angular/common";
import { PixelService } from 'ngx-multi-pixel';
import { filter } from "rxjs";

declare const gtag: any
declare const fbq: any

@Injectable({
  providedIn: 'root',
})
export class TrackingService {
  private window; 

    constructor(
    private _windowRef: WindowReferenceService,
    private router: Router,
    private pixel: PixelService,
    private location: Location) {
      console.log('TrackingService initialized')
      this.window = _windowRef.nativeWindow
      this.trackLocation();
      this.pixel.initialize();
    }

  trackLocation() {
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      filter(() => !!environment.production)
    ).subscribe((event: any) => {
    console.log('TrackingService trackLocation', event)
      // this.pageTrack(event.urlAfterRedirects, this.location)
    })
  }

  protected pageTrack(url: string, location: Location) {
    const path = location.prepareExternalUrl(url)
    try {
      
      this.window.dataLayer.push(
        {
            event: "page_view",
            virtual_page_title: "Explorer",
            virtual_page_location: path,
            virtual_page_referrer: document.referrer
        }
    );

      this.pixel.track('PageView')
    } catch (e) {
      console.warn(e)
    }
  }

  eventTrack(obj: any) {
    try {
      this.window.dataLayer.push(obj)
    } catch (e) {
      console.warn(e)
    }
  }
}

