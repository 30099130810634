import { ChangeDetectionStrategy, Component, DestroyRef, LOCALE_ID, OnInit, Signal, computed, inject, signal } from '@angular/core'
import { CommonModule } from '@angular/common'
import { CDN } from 'src/app/core/constants'
import { AuthFacade, AuthIRepository } from 'auth'
import { LanguageService } from 'src/app/core/services/language.service'
import { MatSelectModule } from '@angular/material/select'
import { MatFormFieldModule } from '@angular/material/form-field'
import { COUNTRIES, COUNTRIES_FOOTER, LANGUAGES, getLangIsoAllowedByYummypets } from 'src/app/domain/lang'
import { FormsModule } from '@angular/forms'
import { Router, RouterModule } from '@angular/router'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { DefaultSessionService } from 'auth/src/lib/services/session.service'
import { COUNTRY_COOKIE } from 'src/app/contants'

@Component({
  selector: 'ex-main-footer',
  standalone: true,
  imports: [
    CommonModule,
    MatSelectModule,
    MatFormFieldModule,
    FormsModule,
    RouterModule
  ],
  templateUrl: './main-footer.component.html',
  styleUrls: ['./main-footer.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class MainFooterComponent implements OnInit {

  readonly #authFacade = inject(AuthFacade)
  readonly #authApi = inject(AuthIRepository)
  readonly #sessionService = inject(DefaultSessionService)
  readonly locale = inject(LOCALE_ID)
  languageService = inject(LanguageService)
  isAuthenticated = this.#authFacade.isAuthenticated
  readonly cdn = CDN

  countries = COUNTRIES_FOOTER
  currentYear: string | undefined
  destroyRef = inject(DestroyRef)

  currentCountry: Signal<string> = computed(() => {
    // 'pt-br-fr'
    console.log('cuuntry :',  `${this.locale}-${this.languageService.country()?.code}`)
    return `${this.locale}-${this.languageService.country()?.code}`
  })
  
  readonly logoSVG = `${CDN.url}logo_explorer-footer.svg`

  ngOnInit(): void {
    this.currentYear = this.getCurrentYear()
    // this.currentCountry = this.languageService.country()?.code

  }

  // updateCurrentCountry() {
  //   console.log('cuntry :',  `${this.locale}-${this.languageService.country()?.code}`)
  //    this.currentCountry = `${this.locale}-${this.languageService.country()?.code}`
  // }

  getCurrentYear(): string {
    return new Date().getFullYear().toString()
  }

  /**
   * - CONNECTED (must patch user lang) + REDIRECT
   * - NOT CONNECTED + COOKIE + REDIRECT
   */
  switchCountry(iso: string) {

    /**
     * - Get cs.lang and lang by country
     */
    
    const id = COUNTRIES_FOOTER.find(e => e.iso === iso)!.id
    const idLanguage = COUNTRIES_FOOTER.find(e => e.iso === iso)!.languages
    const csLangIso = LANGUAGES.find(e => e.id === idLanguage)!.code
    const langIso = getLangIsoAllowedByYummypets(csLangIso)   

    // if(this.#authFacade.isAuthenticated()) {

    //   const userId = this.#authFacade.profile()!.id
    //   this.#authApi.editLangAndCountry(userId, langIso, csLangIso, id)
    //     .pipe(takeUntilDestroyed(this.destroyRef))
    //     .subscribe(() => {
    //       this.#sessionService.set(COUNTRY_COOKIE, id)
    //       window.location.href = `/${csLangIso}/dashboard`
    //   })
    // } else {
    //}
    this.#sessionService.set(COUNTRY_COOKIE, id)
    window.location.href = `/${csLangIso}`
  
    
  }

}

