import { Injectable, LOCALE_ID, inject, signal } from '@angular/core';
import { DefaultSessionService } from 'auth/src/lib/services/session.service';
import { COUNTRIES_ALLOWED_BY_LANG, Country, IsoCountry, getCountryByCode, getCountryById, isAllowedCountry } from 'src/app/domain/lang';
import { LocationRepositoryApi } from 'src/app/data/api/location-repository.api';
import { COUNTRY_COOKIE } from 'src/app/contants';

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  private readonly locale = inject(LOCALE_ID)
  private readonly sessionService = inject(DefaultSessionService)
  private readonly locationService = inject(LocationRepositoryApi)

  private _country = signal<Country | null | undefined>(undefined)
  private _isAllowedCountry = signal<Boolean>(false)

  get country () {
    return this._country.asReadonly()
  }
  
  get isAllowedCountry () {
    return this._isAllowedCountry.asReadonly()
  }

  setCountry (id: number) {
    console.log('setCountry', id)
    const country = getCountryById(id)    
    if(country)
       this._country.set(country)
    else
      this._country.set(null)

    this.setAllowedCountry(id)
    
  }

  setAllowedCountry (countryId: number) {
    const isAllowed = COUNTRIES_ALLOWED_BY_LANG[this.locale].includes(countryId)
    this._isAllowedCountry.set(isAllowed)
  }

  /**
   * - If urlCountryIso is provided by cloudflare we set country
   * - If not we check if the country is set in cookie (by footer)
   * - If not we get country from IP (server)
   * - If not allowed we pop dialog to provide the good country
   */
  init(urlCountryIso?: string) {

    let countryId
    const countryCookieId = this.sessionService.get(COUNTRY_COOKIE) || null

    /**
     * - Param provided by cloudflare (check workers)
     * - Ex: https://explorer.yummypets.com?countryIso=es-mx
     */
    if(urlCountryIso) {

      const id = getCountryByCode(urlCountryIso as IsoCountry).id
      countryId = isAllowedCountry(this.locale, id)? id : null

    } else if (!urlCountryIso && countryCookieId) {

      countryId = isAllowedCountry(this.locale, countryCookieId)? countryCookieId : null
    } 

    if(countryId) {
      this.setCountry(countryId)
    } else {
      this.setCountryByDefault()
    }
      
  }

  setCountryByDefault() {

    /**
     * IF ES-MX || PT-BR we set countryId 
     */
    // const isLatam = [
    //   LangCodes.ES_MX as string, 
    //   LangCodes.PT_BR as string]
    //   .includes(this.locale)

    // if(isLatam) {
    //   const countryId = getCountryByCode(this.locale as IsoCountry).id
    //   this.setCountry(countryId)
    //   return
    // }

    /**
     * ELSE we get country from API
     */
    this.locationService.getCountryFromIP().subscribe((country: any) => { // Country
      if(country) {

        //const id = getCountryById(country.id)?.id

        // if(!id) return

        this.setCountry(country.id)

        //const isAllowed = isAllowedCountry(this.locale, id)

       // console.log('call country from IP is allowed->', isAllowed, country)

        // if(isAllowed) {
        //   this.setCountry(id)
        //   console.log('SET COUNTRY ALLOWEDLLED', this._country.asReadonly())

        // } else {  
         
        //   this.setCountry(0)
        //   console.log('SET COUNTRY NEVER CALLED', this._country.asReadonly())

      }
      // error

    })

  }

}
