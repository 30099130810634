import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { map, skipWhile, take, tap } from 'rxjs/operators';
import { AuthIRepository } from '../data/auth.repository.api';
import { AuthFacade } from '../+state/auth/auth.facade';
import { AUTH_CONFIG_TOKEN } from '../auth.config';

@Injectable()
export class UserGuard  {

    readonly authService = inject(AuthIRepository)
    readonly authFacade = inject(AuthFacade)
    readonly router = inject(Router)
    readonly config = inject(AUTH_CONFIG_TOKEN)

    canActivate() {

        return this.authFacade.isAuthenticated$.pipe(
            skipWhile((value) => value === null),
            take(1),
            map(isAuth => isAuth ? true : false),
            tap(isAuth => !isAuth && this.router.navigate([`${this.config.redirectUserGuardUrl}`]))
        )
    }
}