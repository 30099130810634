import { inject } from "@angular/core"
import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from "@angular/router"
import { AuthFacade } from "auth"
import { filter, take } from "rxjs"

export const userResolver: ResolveFn<any> =
(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

  const authFacade = inject(AuthFacade)
  return authFacade.profile$.pipe(take(1), filter(profile => !!profile))
}

    