import { Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { RouterModule } from '@angular/router'
import { AuthFacade } from 'auth'
import { isMobile } from 'src/app/core/services/utils.services';


@Component({
  selector: 'ex-board-nav',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule
  ],
  templateUrl: './board-nav.component.html',
  styleUrls: ['./board-nav.component.scss']
})
export class BoardNavComponent {

  readonly isMobile = isMobile()
  readonly #authFacade = inject(AuthFacade)

  logout() {
    this.#authFacade.logout()
  }
}
