import { LOCALE_ID, inject } from "@angular/core"
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from "@angular/router"
import { EMPTY, catchError, of, switchMap } from "rxjs"
import { SurveyRepositoryApi } from "src/app/data/api/survey-repository.api"

export const surveyResolver: ResolveFn<any> =
(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

    const router = inject(Router)
    const locale = inject(LOCALE_ID)
    const surveyService = inject(SurveyRepositoryApi)
    const surveyId = +route.params['id']

    return surveyService.fetchStaticSurveys(locale).pipe(
        switchMap((surveys) => {
            const survey = surveys.find((survey => survey.id === surveyId)) 
            return (survey)? of(survey) : surveyService.fetch(surveyId)
        }),   
        catchError(() => {
            router.navigateByUrl('/404')
            return EMPTY
        })
    )

}

    