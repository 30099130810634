<!-- <button class="dialog__close" mat-dialog-close></button> -->

<mat-dialog-content>
  <div class="dialog__wrapper dialog__wrapper--flex text-center">
    <!-- <div class="icon-denied"><img src="https://d2xec21l9srv8z.cloudfront.net/v2/icon_warning.svg"></div> -->
    <p class="page__title" i18n="@@dialog.delete-user.title">Delete my account</p>
    <p class="dialog__msg mb20" i18n="@@dialog.delete-user.desc">
      In order to delete your account and remove permentaly all the datas associated to your <strong>Yummypets</strong> and <strong>Explorer</strong> account, please type « EXPLORER » in the textfield below:
    </p>
    <form [formGroup]="form" (ngSubmit)="onSubmit($event)" novalidate>
      <mat-form-field appearance="outline">
        <input
          matInput
          formControlName="confirm"
          i18n-placeholder="@@placeholderDeleteAccount"
          type="text"
          tabindex="1">
      </mat-form-field>

      <div class="mt20">
        <button
          mat-flat-button
          type="submit"
          [disabled]="!form.valid || isDeleting"
          class="btn btn--primary btn--sm" i18n="@@global.btn.validate">Validate
        </button>
      </div>
    </form>

  </div>
</mat-dialog-content>
