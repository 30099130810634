import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { extractResource } from "auth/src/lib/helpers/extractors";
import { map } from "rxjs";
import { environment } from "src/environments/environment";

@Injectable({ providedIn: 'root' })
export class VoiceRepositoryApi {

    private path = `pots`
    readonly #http = inject(HttpClient);

    getUrl() {
        return `${environment.api}${this.path}`
    }
   
    getStats() {
        return this.#http.get(`${this.getUrl()}/stats`)
                         .pipe(map(extractResource))
    }

    getStatsById(id: number) {
        return this.#http.get(`${environment.api}explorer/users/${id}/stats`)
    }     

}