/* eslint-disable prefer-const */
import { Injectable, inject , } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Observable, forkJoin, map } from "rxjs";
import { AUTH_CONFIG_TOKEN } from "../auth.config";
import { JSONObject } from 'yummypets-js-core'
import { fetchAsHttpParams } from "../helpers/fetch-as-http-params";
import { extractResource, extractResourceInCollection } from "../helpers/extractors";
import { isConsumerFullfilled } from "../domain/user-consumer.utils";
import { Pet } from "../domain/pet.model";
import { PetMapper } from "./pet.mapper";
import { ConsumerData } from "../domain/consumer-data.model";
import { ConsumerMapper } from "./data-consumer.mapper";
import * as moment from "moment";
import { ExpirationService } from "../services/expiration.service";

@Injectable()
export class PetIRepository { // REPLACE BY YUMMYPETS-JS-CORE

    readonly path = `pets`
    readonly http = inject(HttpClient)
    readonly config = inject(AUTH_CONFIG_TOKEN)
    readonly #expirationService = inject(ExpirationService)


    private getUrl() {
        return `${this.config.api}${this.path}`;
    }

    create(data: FormData): Observable<Pet> {

        const params = fetchAsHttpParams(data)
        return this.http.post(`${this.getUrl()}`, params).pipe(
            map(extractResource),
            map((resp: JSONObject) => this.transformAsPet(resp)))
    }

    edit(id: number, data: FormData): Observable<Pet> {

        const params = fetchAsHttpParams(data)
        return this.http.put(`${this.getUrl()}/${id}`, params).pipe(
            map(extractResource),
            map((resp: JSONObject) => this.transformAsPet(resp)))
    }

    delete(id: number): Observable<any> {
        return this.http.delete(`${this.getUrl()}/${id}`)
    }

    /**
     * @returns 'cs_datas_expiraztion' : ISO 8601
     */
    getConsumerDataExpiration() {
        return this.http.get(`${this.config.api}explorer/settings`)
    }

    fetchConsumerData(id: number): Observable<any> {

        // const sources$: any[] = [
        //     this.http.get(`${this.getUrl()}/${id}/consumer/datas`),
        //     this.getConsumerDataExpiration()
        // ]

        // return forkJoin(sources$).pipe(map((response: any) => {

        //     let [pet, expiration] = response

        return this.http.get(`${this.getUrl()}/${id}/consumer/datas`)
            .pipe(map((pet: JSONObject) => {
                
                let collection = {} as [key: string, value: ConsumerData]

                  // TODO: CREATE METHOD
                pet.collection.map((data:JSONObject) => {
                    const isConsumerDataAllowed = !['identification'].some(name => name === data.resource.type.name)
                    if (isConsumerDataAllowed) {
                        collection[data.resource.type.name] = new ConsumerMapper().mapFromEntity(data.resource)
                    }
                })

                let isExpired = false
                let lastUpdatedTime = null
    
                // TODO: CREATE METHOD
                if(pet?.extras?.last_updated_time) {
                    const currentDay = moment() 
                    const duration = this.#expirationService.getDuration()
                    lastUpdatedTime = currentDay.diff(pet?.extras?.last_updated_time, 'days')
                    isExpired = (lastUpdatedTime > duration.asDays())
                }    

                return {
                    collection,
                    isComplete : pet?.extras?.completed || false,
                    uncomplete : pet?.extras?.uncomplete_datas,
                    isExpired
                }
            
            }))
    }

    fetchPet(id: number, consumer = false): Observable<Pet> {

        let sources$ = [
            this.http.get(`${this.getUrl()}/${id}`).pipe(
                map(extractResource),
                map((resp: JSONObject) => this.transformAsPet(resp)))
        ]

        if (consumer) {
            const consumerRequest = this.fetchConsumerData(id)
            sources$.push(consumerRequest)
        }

        return forkJoin(sources$).pipe(map((response: any) => {
            let [pet, consumer] = response

            // builder?
            if (consumer) {
                pet.csData = consumer
                pet.isCsDataFullfilled = isConsumerFullfilled(pet.csData.collection)
            }

            return pet
        }))

    }

    private transformAsPet(json: JSONObject): Pet {
        return new PetMapper().mapFromEntity(json)
    }
    
}