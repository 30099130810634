<div class="navbar__links">

    <!-- LANDING ONLY -->
    <!-- <a class="navbar__link is-mobile"
        [routerLink]="'/signup'"
        routerLinkActive="active">
        Signup
    </a> -->

    <!-- AUTH ONLY -->
    <a class="navbar__link"
        *ngIf="isAuth$ | async"
        [routerLink]="'/dashboard'" 
        routerLinkActive="active" >
        <span i18n="@@header.navbar.dashboard">Dashboard</span>
    </a>

    <!-- NOT AUTH ONLY -->
    <a class="navbar__link"
        *ngIf="(isAuth$ | async) === false"
        [routerLink]="'/panel'"
        routerLinkActive="active">
        <span i18n="@@header.navbar.panel">Panel</span>
    </a>

    <!-- NOT AUTH ONLY -->
    <a class="navbar__link"
        *ngIf="(isAuth$ | async) === false"
        [routerLink]="'/surveys'" 
        routerLinkActive="active">
        <span i18n="@@header.navbar.surveys">Surveys</span>
    </a>

    <a class="navbar__link"
        [routerLink]="'/question-of-the-day'" 
        routerLinkActive="active">
        <span i18n="@@header.navbar.qod">Questions</span>
    </a>

        <a class="navbar__link"
        [routerLink]="'/shelters'" 
        routerLinkActive="active">
        <span i18n="@@header.navbar.shelters">Shelters</span>
    </a>


</div>
