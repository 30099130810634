import { Component, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { Router, RouterModule } from '@angular/router'
import { of } from 'rxjs'
import { AuthFacade } from 'auth'

@Component({
  selector: 'ex-navbar-links',
  standalone: true,
  imports: [
    CommonModule, 
    RouterModule
  ],
  templateUrl: './navbar-links.component.html',
  styleUrls: ['./navbar-links.component.scss']
})
export class NavbarLinksComponent {

  authFacade = inject(AuthFacade)
  isAuth$ = this.authFacade.isAuthenticated$

}
