export const environment = {
    project: 'explorer',
    production: false,
    name:"staging",
    logLevel: 'TRUE',
    api: 'https://api-preprod.yummypets.com/',
    hmr: false,
    secure: false,
    httpOnly: false,
    domain: '',
};