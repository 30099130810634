<footer class="footer">
    <div class="footer__grid wrapper">
        <div class="footer__logo">
            <img [src]="logoSVG" alt="Explorer Logo">
        </div>

        <div class="footer__lang">

            @if(languageService.country() && !isAuthenticated()) {
            <mat-form-field appearance="outline" >
            
                    <mat-select panelClass="footer__select" [ngModel]="currentCountry()" name="lang">
                        <!-- @for(country of countries; track country.id) {
                            <mat-option [value]="country.code" (click)="switchCountry(country.id)" >
                                {country.iso, select, gb {United Kingdom (English)} us {United States (English)} ca {Canada (English)} fr {France (Français)} pt-br {Brasil (Português)} es-mx {México (Español)}}
                            </mat-option>
                        } -->
                        @for(country of countries; track country.id) {
                            <mat-option [value]="country.iso" (click)="switchCountry(country.iso)">
                                {country.iso, select, en-gb {United Kingdom (English)} en-us {United States (English)} en-ca {Canada (English)} fr-ca {Canada (Français)} fr-fr {France (Français)} pt-br {Brasil (Português)} es-mx {México (Español)}}
                            </mat-option>
                        }
                    </mat-select>
            </mat-form-field>
            }
        </div>

        <hr class="footer__line">

        <div class="footer__links d-flex">
            <ul>
                <li><a routerLink="/legal" i18n="@@global.link.term-of-use">Term of use</a></li>
                <li><a routerLink="/privacy" i18n="@@global.link.privacy">Privacy policy</a></li>
                <li><a routerLink="/cookies" i18n="@@global.link.cookie">Cookie policy</a></li>
            </ul>
            <ul>
                <li><a href="mailto:explorer@yummypets.com" i18n="@@footer.link.contact">Contact us</a></li>
                <li><a href="https://www.facebook.com/yummypets/" target="_blank" i18n="@@footer.link.facebook">Facebook</a></li>
                <li><a href="https://fr.linkedin.com/company/yummypets" target="_blank" i18n="@@footer.link.linkdin">Linkedin</a></li>
            </ul>
        </div>
        
        <div class="footer__copyright">
            <div class="footer__mobile">
                <a class="app-android" href="https://apps.apple.com/app/6478925036">
                    <img [src]="cdn.v3 + 'badges/svg/AppStore_' + locale +'.svg'"/>
                </a>
                <a class="app-ios" href="https://play.google.com/store/apps/details?id=com.yummypets.explorer">
                    <img [src]="cdn.v3 + 'badges/svg/GooglePlay_' + locale +'.svg'"/>
                </a>
            </div>
            <a href="https://www.yummypets.com" target="_blank"><i class=""></i> Yummypets © 2011-{{ currentYear }}</a>
        </div>  
    </div>
</footer>