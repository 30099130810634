import { Injectable, inject } from '@angular/core'
import { createEffect, Actions, ofType, concatLatestFrom } from '@ngrx/effects'
import { catchError, of, map, exhaustMap, tap, switchMap } from 'rxjs'
import * as PetsActions from './pets.actions'
import * as fromPets from './pets.selectors'
import { PetIRepository } from '../../data/pet-profile.repository.api'
import { AuthIRepository } from '../../data/auth.repository.api'
import { DefaultSessionService } from '../../services/session.service'
import { SHARED_SESSION_KEYS } from 'yummypets-js-core'
import { Store } from '@ngrx/store'
import { Pet } from '../../domain/pet.model'
import { Router } from '@angular/router'
import { AUTH_CONFIG_TOKEN } from '../../auth.config'

@Injectable()
export class PetsEffects {

  private store = inject(Store)
  private actions$ = inject(Actions)
  private router = inject(Router)
  private config = inject(AUTH_CONFIG_TOKEN)
  private petService = inject(PetIRepository)
  private authService = inject(AuthIRepository)
  private sessionService = inject(DefaultSessionService)

  loadPets$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PetsActions.loadPets), 
      exhaustMap((payload) => this.authService.fetchPetsWithCs(payload.userId).pipe(
        map((pets) => PetsActions.loadPetsSuccess({ pets })),
        catchError((error) => {
          return of(PetsActions.loadPetsFailure({ error: error.message }))
        })
      )
    ))
  )

  loadPetsSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PetsActions.loadPetsSuccess), 
      tap((payload) => {})),
      {dispatch: false}
  )

  /**
   * - LOAD CURRENT PET EFFECTS
   */
  loadPet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PetsActions.loadPet), 
      exhaustMap((payload) => this.petService.fetchPet(payload.id, true).pipe(
        map((pet: Pet) => PetsActions.loadPetSuccess({ pet })),
        catchError((error) => of(PetsActions.loadPetFailure({ error: error.message })))
      )
    ))
  )

  loadPetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PetsActions.loadPetSuccess),   
      tap((payload) => {
        this.sessionService.set(SHARED_SESSION_KEYS.pet, payload.pet)
      })
  ), {dispatch: false})

  /**
   * - ADD PET EFFECTS
   */
  addPet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PetsActions.addPet), 
      exhaustMap((payload) => this.authService.addPet(payload.userId, payload.pet ).pipe(
        switchMap(pet => this.petService.fetchConsumerData(pet.id).pipe(map(cs => [pet, cs]))),
        map((datas) => {
          /**
           * MERGE PROFILE WITH CS DATA CREATE METHOD
           */
          const [pet, cs] = datas
          pet.csData = cs

          return PetsActions.addPetSuccess({ pet })}),
        catchError((error) => of(PetsActions.addPetFailure({ error: error.message })))
      )
  )))

  /**
   * TODO : WE SHOULD NOT RECALL THE API 
   */
  addPetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PetsActions.addPetSuccess),
      tap((payload) => {
        this.sessionService.set(SHARED_SESSION_KEYS.pet, payload.pet)
      })
  ), {dispatch: false})

  /**
   * - EDIT CURRENT PET EFFECTS
   */
  editPet$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PetsActions.editPet), 
      concatLatestFrom(() => this.store.select(fromPets.currentPet)),
      exhaustMap(([payload, pet]) => this.petService.edit(pet!.id, payload.pet).pipe(
        map((pet) => PetsActions.editPetSuccess({ pet })),
        catchError((error) => of(PetsActions.editPetFailure({ error: error.message })))
      )
  )))

  /**
   * The api does not return a consumer pet on edit 
   * So we need to reload the pet with LoadPet method
   */
  reloadPetOnEditpetSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(PetsActions.editPetSuccess),
      map((payload) => PetsActions.loadPet({ id: payload.pet.id }))))
}








