import { Component, LOCALE_ID, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { CardSurveyComponent } from './pages/surveys/ui/card-survey/card-survey.component';
import { LandingComponent } from './pages/landing/landing.component';
import { MainFooterComponent } from './shared/main-footer/main-footer.component';
import { MainHeaderComponent } from './shared/main-header/main-header.component';
import { AuthFacade } from 'auth';
import { DefaultSessionService } from 'auth/src/lib/services/session.service';
import { LanguageService } from './core/services/language.service';
import { NotifyModule } from './shared/notify/notify.module';
import { environment } from 'src/environments/environment';
import { COUNTRY_URL_PARAM } from './contants';
import * as moment from 'moment';
import { ExpirationService } from 'auth/src/lib/services/expiration.service';
import { ConsumerIRepository } from 'auth/src/lib/data/consumer.repository';
import { TrackingService } from './core/services/tracking.service';


@Component({
  standalone: true,
  imports: [
    LandingComponent,
    CardSurveyComponent,
    MainHeaderComponent,
    MainFooterComponent,
    NotifyModule,
    RouterModule,
  ],
  providers: [],
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  readonly locale = inject(LOCALE_ID)
  readonly #router = inject(Router)
  readonly #petFacade = inject(AuthFacade)
  readonly #trackingService = inject(TrackingService)
  readonly #authFacade = inject(AuthFacade)
  readonly #languageService = inject(LanguageService)
  readonly #expirationService = inject(ExpirationService)
  readonly #consumerRepository = inject(ConsumerIRepository)
  readonly #sessionService = inject(DefaultSessionService)

  constructor() {

    /**
     * - Reset errors from auth store on route change
     */
    this.#router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.#authFacade.resetError()
        this.#petFacade.resetError()
       }
    })
  }

  ngOnInit() {

    console.log('WELCOME TO EXPLORER :', environment.name, environment.production)

    // - LANGUAGE
    moment.locale(this.locale)

    // - EXPIRATION
    this.#consumerRepository.getConsumerDataExpiration().subscribe((expiration) => {
      this.#expirationService.setExpiration(expiration)

        // - LOAD USER
        const user = this.#sessionService.getUser()
        if (user) {
          this.#authFacade.reload(user.id)
        } else {
          this.#authFacade.loadNoUser()
        }
    })

    // - COUNTRY
    const urlCountryIso = new URLSearchParams(window.location.search).get(COUNTRY_URL_PARAM) || ''
    this.#languageService.init(urlCountryIso)

    // - UNREGISTER OLD SERVICE WORKER
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then((registrations) => {
        for (const registration of registrations) {
          registration.unregister()
        }
      })
    }
  }
}
