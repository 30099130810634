<div class="notify__wrapper">
  <div class="notify__card__wrapper" [ngClass]="item.class" *ngFor="let item of notificationItems" [@notify]="'card'">
    <div class="card relative notify__card">

      <div class="notify__card__1">
        <div class="notify__card__2 mr-3">

          <div class="notify__icon" [ngClass]="item.type"></div>

          <div class="ml-3">
            <p class="title notify__title" [innerHtml]="item.title"></p>
            <p class="notify__message" [innerHtml]="item.message"></p>
          </div>

        </div>

        <!-- <a
          *ngIf="item.showClose"
          role="button"
          class="yp-icn-cross dialog__close notify__close ml-auto"
          (click)="close(item.id)"></a> -->
      </div>
    </div>
  </div>
</div>


