import { Mapper, User } from "yummypets-js-core";
import { UserConsumerEntity } from "../domain/user.model";
import { ConsumerData, ConsumerDataEntity, ConsumerDataTypeEntity } from "../domain/consumer-data.model";
import { consumerValueExtractor } from "../domain/user-consumer.utils";

export class ConsumerMapper implements Mapper<ConsumerDataEntity, ConsumerData> {

    /**
     * Serialize to DTO
     * Get API response and map it to the domain model ConsumerData
     */
    mapFromEntity(item: ConsumerDataEntity): ConsumerData {

        let cs = <ConsumerData>{}

        try {

            const type = item.type as ConsumerDataTypeEntity
            cs.id = type.id
            cs.name = `cs.${type.name}`
            cs.unique = type.unique    
            cs.slug = type.slug
            cs.valueType = type.value_type
            cs.value = consumerValueExtractor(cs, item.values)
      
        } catch (error) {
            console.warn(`Error on ${this.constructor.name}'s mapFromEntity function : `, error)
        }

        return cs
    }
}