import { Injectable, inject } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { DialogDeleteComponent } from './dialog-delete-account/dialog-delete-account.component';
import { DialogInformationComponent } from './dialog-information/dialog-information.component';
import { DialogLangCountryComponent } from './dialog-lang-country/dialog-lang-country.component';
import { DialogBlockComponent } from './dialog-block/dialog-block.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  dialog = inject(MatDialog)

  deleteAccount(): Observable<any> {
    let dialogRef: MatDialogRef<DialogDeleteComponent>
    dialogRef = this.dialog.open(DialogDeleteComponent, {width: '700px'})
    return dialogRef.afterClosed()
  }

  displayInformation(params: any): Observable<any> {
    let dialogRef: MatDialogRef<DialogInformationComponent>
    dialogRef = this.dialog.open(DialogInformationComponent, {
      panelClass: 'dialog-header',
      data: { title: params.title, desc: params.desc },
      width: '700px'
    })
    return dialogRef.afterClosed()
  }

  blockLocation() {
    let dialogRef: MatDialogRef<DialogBlockComponent>
    dialogRef = this.dialog.open(DialogBlockComponent, {
      panelClass: 'dialog-header',
    })
    return dialogRef.afterClosed()
  }

  setCountryAndLang() {
    return this.dialog.open(DialogLangCountryComponent)
  }



}
