import { HttpClient } from "@angular/common/http";
import { Injectable, inject } from "@angular/core";
import { AUTH_CONFIG_TOKEN } from "../auth.config";
import { map } from "rxjs";

@Injectable()
export class ConsumerIRepository  {

    readonly http = inject(HttpClient)
    readonly config = inject(AUTH_CONFIG_TOKEN)

    getConsumerDataExpiration() {
        return this.http.get(`${this.config.api}explorer/settings`).pipe(map((resp: any) => resp['cs_datas_expiration']))
    }

}