import { createFeatureSelector, createSelector } from '@ngrx/store'
import { AUTH_FEATURE_KEY, AuthState } from './auth.reducer'

// Lookup the 'Auth' feature state managed by NgRx
export const selectAuthState =
  createFeatureSelector<AuthState>(AUTH_FEATURE_KEY)

export const error = createSelector(
  selectAuthState,
  (state: AuthState) => state.error
)

export const profile = createSelector(
  selectAuthState,
  (state: AuthState) => state.profile
)

export const isAuthenticated = createSelector(
  selectAuthState,
  (state: AuthState) => state.isAuthenticated
)

export const loaded = createSelector(
  selectAuthState,
  (state: AuthState) => state.loaded
)

export const isLoading = createSelector(
  selectAuthState,
  (state: AuthState) => state.isLoading
)

export const isValidated = createSelector(
  selectAuthState,
  (state: AuthState) => state.isValidated
)

export const isUserDataFullfilled = createSelector(
  selectAuthState,
  (state: AuthState) => state.isDataFullfilled
)

export const isUserCsDataFullfilled = createSelector(
  selectAuthState,
  (state: AuthState) => state.isCsDataFullfilled
)

export const isUserCsDataExpired = createSelector(
  selectAuthState,
  (state: AuthState) => state.isCsDataExpired
)

// export const selectAllAuth = createSelector(
//   selectAuthState,
//   (state: AuthState) => selectAll(state)
// )

// export const selectAuthEntities = createSelector(
//   selectAuthState,
//   (state: AuthState) => selectEntities(state)
// )

// export const selectSelectedId = createSelector(
//   selectAuthState,
//   (state: AuthState) => state.selectedId
// )

// export const selectEntity = createSelector(
//   selectAuthEntities,
//   selectSelectedId,
//   (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
// )
