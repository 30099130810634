import { Directive, ElementRef, HostListener, Renderer2 } from '@angular/core'

@Directive({
  selector: '[exScrollAnimation]',
  standalone: true
})
export class ScrollAnimationDirective {

  constructor(private el: ElementRef, private renderer: Renderer2) { }

  @HostListener('window:scroll', [])
  onWindowScroll() {
  
    const element = this.el.nativeElement;
    const rect = element.getBoundingClientRect();
    const windowHeight = window.innerHeight;
    
    if (rect.top < windowHeight && rect.bottom >= 0) {
      this.renderer.addClass(element, 'start'); // Ajoutez votre classe CSS d'animation ici
    } else {
      this.renderer.removeClass(element, 'start');
    }
  }
}
