import { Component, OnInit, ChangeDetectionStrategy, Inject, inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { AuthFacade } from 'auth'

@Component({
  standalone: true,
  selector: 'yp-dialog-block',
  templateUrl: './dialog-block.component.html',
  styleUrls: ['./dialog-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule
  ]
})
export class DialogBlockComponent implements OnInit {

  authFacade = inject(AuthFacade)
  router = inject(Router)

  constructor(
    public dialogRef: MatDialogRef<DialogBlockComponent>,
  ) {}

  ngOnInit() {
    this.dialogRef.disableClose = true
  }

  confirm() {
    if(this.authFacade.isAuthenticated())
      this.authFacade.logout()
    
    this.router.navigateByUrl('/')
    this.dialogRef.close()
  }
}
