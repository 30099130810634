import { Pet } from "yummypets-js-core"
import { User } from "./user.model"
import { ConsumerData } from "./consumer-data.model"

/**
 * - CONSUMER DATA
 * 
 * @see https://api-preprod.yummypets.com/docs/#api-Consumer-Datas
 * @see https://docs.google.com/document/d/12PZ6q7OIcTyObDHPv2kKqnMRZ72olSM09nv2Y2QkheE/edit?pli=1
 */
export type consumerDataType = {
    id: number,
    name: string,
    /**
     * @description ID
     * @see https://api-preprod.yummypets.com/docs/#api-Consumer-DataValues
     * 
     * @description product_brand_id
     * @see https://api-preprod.yummypets.com/docs/#api-Product-Brands
     */
    valueType: 'text' | 'bool' | 'product_brand_id' | 'id',
    consumerTypeId: 18 | 1, // USER | PET
    unique: boolean
}


enum ConsumerDataValueType {
    TEXT= 'text',
    BOOL= 'bool',
    PRODUCT_BRAND_ID= 'product_brand_id',
    ID= 'id'
}
export type consumerDataValueType = 'text' | 'bool' | 'product_brand_id' | 'id'

export interface ConsumerProductBrandValue {
    id: number,
    image: any
    lib: String
    averageNote: number
}

export function isConsumerFullfilled(consumerData: {[key: string]: ConsumerData}): boolean {
    return !Object.values(consumerData).some((element: ConsumerData) => element['value'] === null)
}

export function consumerValueExtractor(cs: Omit<ConsumerData, "value">, value: any) {

    /**
     * - SPECIAL CASES
     * - health issue can be empty 
     * - PRODUCT_BRAND_ID fields can be empty
     */
    if(cs.valueType === ConsumerDataValueType.BOOL)
        return (value[0] !== undefined)? value[0] : null

    if(cs.valueType === ConsumerDataValueType.ID && cs.unique)
        return value[0]?.id || null

    /**
     * FOOD_TYPE ID + SLUG 
     */
    if(cs.valueType === ConsumerDataValueType.ID && !cs.unique)
        return value.map((value: {id: number}) => value) || null
        // return value.map((value: {id: number}) => value.id) || null

    if(cs.valueType === ConsumerDataValueType.TEXT && cs.unique)
        return value[0] || null

    if(cs.valueType === ConsumerDataValueType.TEXT && !cs.unique)
        return value.map((value: string) => value) || null

    if(cs.valueType === ConsumerDataValueType.PRODUCT_BRAND_ID && cs.unique) {
        return value[0] as ConsumerProductBrandValue || ''
    }
    if(cs.valueType === ConsumerDataValueType.PRODUCT_BRAND_ID && !cs.unique) {
        return value.map((value: ConsumerProductBrandValue) => value) || []
    }
}


export function consumerExtractor(json: any): User | Pet {

    const consumerData = <any>{}

    try {
        /**
         * Determine content of node "values"
         */

        json.map((item: any) => {

            if(item.type.unique && item.type.value_type !== "bool") {
              consumerData['cs.' + item.type.name] = (item.values[0])? item.values[0].id : undefined
            } else if(item.type.unique && item.type.value_type == "bool") {
              consumerData['cs.' + item.type.name] = item.values[0]
            } else if(item.type.value_type === "product_brand_id") {
              consumerData['cs.' + item.type.name] = item.values.map((value: any) => value)
            } else {
              consumerData['cs.' + item.type.name] = item.values.map((value: any) => value.id)
            }
        })

        return consumerData

    } catch(e) {
        throw new Error("Error while extracting consumer data")
    }
}