import { Component, OnDestroy, inject } from '@angular/core'
import { ReactiveFormsModule, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog'
import { AuthFacade, AuthIRepository } from 'auth';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { deleteAccountValidator } from '../../validators/delete-account.validator';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@Component({
  selector: 'ex-dialog-delete-account',
  imports: [
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule
  ],
  standalone: true,
  templateUrl: './dialog-delete-account.component.html',
  styleUrls: ['./dialog-delete-account.component.scss'],
})
export class DialogDeleteComponent implements OnDestroy {

  dialogRef = inject(MatDialogRef<DialogDeleteComponent>)
  #authfacade = inject(AuthFacade)
  #userService = inject(AuthIRepository)

  form =  inject(UntypedFormBuilder).group({
    confirm : ['', [
      Validators.required,
      deleteAccountValidator()
    ]]
  });
  destroyed$ = new Subject()
  isDeleting = false
  readonly confirmTxt = 'CONFIRM'

  onSubmit(e: any) {
    const user = this.#authfacade.profile()

    if(!this.form.valid) return
    if(!user) return

    this.isDeleting = true
    this.#userService.delete(user.id).pipe(
      takeUntil(this.destroyed$),
    ).subscribe(resp => {
        this.dialogRef.close()
        this.#authfacade.logout()
      }, (err) => {
        
        // notify
      
      }, () =>  {
        this.isDeleting = false
        this.dialogRef.close()
      })
  }

  ngOnDestroy() {
    this.destroyed$.next(null)
    this.destroyed$.complete()
  }
}
