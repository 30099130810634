import { createFeatureSelector, createSelector } from '@ngrx/store'
import { PETS_FEATURE_KEY, PetsState } from './pets.reducer'

// Lookup the 'Pets' feature state managed by NgRx
export const selectPetsState =
  createFeatureSelector<PetsState>(PETS_FEATURE_KEY)

  export const errorPet = createSelector(
    selectPetsState,
    (state: PetsState) => state.error
  )
  
  export const currentPet = createSelector(
    selectPetsState,
    (state: PetsState) => state.currentPet
  )

  export const pets = createSelector(
    selectPetsState,
    (state: PetsState) => state.pets
  )
  
  export const loadedPet = createSelector(
    selectPetsState,
    (state: PetsState) => state.loadedPet
  )
  
  export const isLoadingPet = createSelector(
    selectPetsState,
    (state: PetsState) => state.isLoadingPet
  )

  export const isDataFullfilled = createSelector(
    selectPetsState,
    (state: PetsState) => state.isDataFullfilled
  )

  export const isCsDataFullfilled = createSelector(
    selectPetsState,
    (state: PetsState) => state.isCsDataFullfilled
  )

  export const isCsDataExpired = createSelector(
    selectPetsState,
    (state: PetsState) => state.isCsDataExpired
  )


// const { selectAll, selectEntities } = petsAdapter.getSelectors()

// export const selectPetsLoaded = createSelector(
//   selectPetsState,
//   (state: PetsState) => state.loaded
// )

// export const selectPetsError = createSelector(
//   selectPetsState,
//   (state: PetsState) => state.error
// )

// export const selectAllPets = createSelector(
//   selectPetsState,
//   (state: PetsState) => selectAll(state)
// )

// export const selectPetsEntities = createSelector(
//   selectPetsState,
//   (state: PetsState) => selectEntities(state)
// )

// export const selectSelectedId = createSelector(
//   selectPetsState,
//   (state: PetsState) => state.selectedPetId
// )

// export const selectEntity = createSelector(
//   selectPetsEntities,
//   selectSelectedId,
//   (entities, selectedId) => (selectedId ? entities[selectedId] : undefined)
// )

// export const selectEntity = createSelector(
//   selectEntities,
//   (entities, props) => entities[props.id]
// );

// export const selectEntitiesByID = createSelector(
//   selectEntities,
//   (entities, props) => props.ids.map(id => entities[id])
// );

