import { Injectable } from "@angular/core";
import * as moment from "moment";

@Injectable({ providedIn: 'root' })
export class ExpirationService {
    
    private _expiration: string | null = null
    
    setExpiration(exp: string) {
        this._expiration = exp || null
    }

    get expiration () {
        return this._expiration
    }

    getDuration() {
        return moment.duration(this._expiration)
    }

}