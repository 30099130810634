import { NgModule } from '@angular/core';
import { NotifyComponent } from './notify.component';
import { NotifyService, notifyServiceFactory } from './notify.service';
import { NotifyConfig } from './notify.config';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

@NgModule({
  imports: [
    RouterModule,
    CommonModule
  ],
  declarations: [
    NotifyComponent
  ],
  exports: [
    NotifyComponent
  ],
  providers: [
    NotifyService, // Create a factory ?
    NotifyConfig
    //{ provide: NotifyService, useFactory: notifyServiceFactory, deps: [NotifyConfig] }
  ]
})
export class NotifyModule { }
