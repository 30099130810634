import { createAction, props } from '@ngrx/store'
import { Pet } from '../../domain/pet.model'

const prefix = '[AUTH PETS]'

/**
 * - LOAD PETS
 */
export const loadPets = createAction((`${prefix} LOAD_PETS`), props<{ userId: number }>())
export const loadPetsSuccess = createAction(`${prefix} LOAD_PETS_SUCCESS`, props<{ pets: Pet[] }>())
export const loadPetsFailure = createAction(`${prefix} LOAD_PETS_FAIL`, props<{ error: any }>())

/**
 * - LOAD CURRENT PET 
 */
export const loadPet = createAction((`${prefix} LOAD_CURRENT_PET`), props<{ id: number }>())
export const loadNoPet = createAction((`${prefix} LOAD_NO_PET`))
export const loadPetSuccess = createAction(`${prefix} LOAD_CURRENT_PET_SUCCESS`, props<{ pet: Pet }>())
export const loadPetFailure = createAction(`${prefix} LOAD_CURRENT_PET_FAIL`, props<{ error: any }>())

/**
 * - SWITCH PET
 */
export const setDefaultPet = createAction((`${prefix} SET_DEFAULT_PET`), props<{ pet: Pet }>())
export const setDefaultPetSuccess = createAction((`${prefix} SET_DEFAULT_PET_SUCCESS`), props<{ pet: Pet }>())
export const selectCurrentPet = createAction('SELECT_CURRENT_PET', props<{ id: number }>());

/**
 * - EDIT CURRENT PET 
 */
export const editPet = createAction((`${prefix} EDIT_PET`), props<{ pet: any }>())
export const editPetSuccess = createAction((`${prefix} EDIT_PET_SUCCESS`), props<{ pet: Pet }>())
export const editPetFailure = createAction(`${prefix} EDIT_PET_FAIL`, props<{ error: any }>())

/**
 * - ADD PET
 */
export const addPet = createAction((`${prefix} ADD_PET`), props<{ userId: number , pet: FormData }>())
export const addPetSuccess = createAction((`${prefix} ADD_PET_SUCCESS`), props<{ pet: Pet }>())
export const addPetFailure = createAction(`${prefix} ADD_PET_FAIL`, props<{ error: any }>())

/**
 * - DELETE PET
 */


/**
 * - RESET ERROR
 */
export const resetPetError = createAction(`${prefix} ERROR_RESET`);


/**
 * PURGE
 */
export const purge = createAction(`${prefix} PURGE`);

