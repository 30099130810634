import { Injectable, inject } from '@angular/core'
import { DefaultStorageService } from './storage.service';
import { SHARED_SESSION_KEYS } from 'yummypets-js-core';
import { AUTH_CONFIG_TOKEN } from '../auth.config';


const expireDate = new Date()
expireDate.setDate(expireDate.getDate() + 7)

// MOVE FROM HERE
export const COOKIE_OPTIONS = {
    path: '/',
    expires: expireDate,
    domain: '',
    secure:false,
    httpOnly: false
  }


@Injectable({
    providedIn: 'root',
})
export class DefaultSessionService {

    private readonly config = inject(AUTH_CONFIG_TOKEN)
    storageService = inject(DefaultStorageService)

    private keyWithPrefix(key: string) {
        return `${this.config.project}_${key}`
    }

    /**
     * - ABSTRACT METHODS
     */
    set(key:string, value: any):void {

        this.storageService.setItem(this.keyWithPrefix(key), JSON.stringify(value), COOKIE_OPTIONS)
    }

    get(key: string) {
        const value = this.storageService.getItem(this.keyWithPrefix(key))
        return value ? JSON.parse(value) :  null;
    }

    remove(key: string) {
        this.storageService.remove(this.keyWithPrefix(key), COOKIE_OPTIONS)
    }

    /**
     * - SPECIFICS METHODS
     */
    setToken(token: string) {
        this.set(SHARED_SESSION_KEYS.token, token)
    }

    getToken() {    
        return this.get(SHARED_SESSION_KEYS.token)  
    }

    removeToken() {
        this.remove(SHARED_SESSION_KEYS.token)
    }

    setLang(value:any) {
        this.set(SHARED_SESSION_KEYS.lang, value)
    }

    getLang() {
        return this.get(SHARED_SESSION_KEYS.lang)
    }

    removeLang() {
        this.remove(SHARED_SESSION_KEYS.lang)
    }

    setOrigin(origin: number) {
        this.set(SHARED_SESSION_KEYS.origin, origin)
    }

    getOrigin() {
        return this.get(SHARED_SESSION_KEYS.origin)
    }

    removeOrigin() {
        this.remove(SHARED_SESSION_KEYS.origin)
    }

    setUser(user:any) {
        this.set(SHARED_SESSION_KEYS.user, user)
    }

    getUser() {
        return this.get(SHARED_SESSION_KEYS.user)  
    }

    setPet(pet:any) {
        this.set(SHARED_SESSION_KEYS.pet, pet)
    }

    getPet() {
        return this.get(SHARED_SESSION_KEYS.pet)  
    }

}
