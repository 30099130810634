import { EnvironmentInjector } from "@angular/core"
import { environment } from "src/environments/environment"

export const CDN = {
    url: 'https://d2xec21l9srv8z.cloudfront.net/v2/',
    v3: 'https://d2xec21l9srv8z.cloudfront.net/v3/'
}

export const URLS = {
    api: 'https://api.exemplar.ai/v2/',
}

export const REGEX_UPPERCASE = /[A-Z]+/
export const REGEX_LOWERCASE = /[a-z]+/
export const REGEX_NUMBER  = /[0-9]+/
export const REGEX_SPECIALCHAR = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/


export enum PasswordStrength {
    WEAK = 'weak',
    MEDIUM = 'medium',
    STRONG = 'strong',
}
  