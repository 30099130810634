<form [formGroup]="form" (submit)="onSubmit()" > <!--[class]="classList"-->

  <label class="form__label">
    <ng-container i18n="@@user.form.lang.label">Language</ng-container>
    <span class="form__required"> *</span>
  </label>
  <mat-form-field appearance="outline">
      <mat-select
      (selectionChange)="onChangeLang($event)"
      appearance="outline"
      tabindex="1"
      formControlName="cs.lang"
      required>
      <mat-option *ngFor="let lang of langs"
          [value]="lang.iso">{{ lang.lib }}</mat-option>
      </mat-select>
  </mat-form-field>

  <label class="form__label">
    <ng-container i18n="@@pet.form.country.label">Country</ng-container>
    <span class="form__required"> *</span>
</label>    
<mat-form-field appearance="outline">
    
    <mat-select
    appearance="outline"
    formControlName="country_id"
    tabindex="2"
    required>
        <mat-option *ngFor="let country of countries" [value]="country.id">
            <span i18n="@@form.countries.select">
                {{country.name}}
            <!-- {country.name, select,
                Brazil {Brazil}
                Canada {Canada}
                France {France}
                Belgium {Belgium}
                United Kingdom {United Kingdom}
                United States {United States}
                Mexico {Mexico}
            } -->
            </span>
        </mat-option>
    </mat-select>
</mat-form-field>


  <!-- <div [ngClass]="{'flex-column': true}">
    <mat-form-field appearance="outline">
      <mat-select
        (selectionChange)="onChangeLang($event)"
        appearance="outline"
        tabindex="1"
        formControlName="cs.lang"
        placeholder="Select a lang"
        i18n-placeholder="@@form.select.lang"
        required>
        <mat-option
          *ngFor="let lang of langs"
          [value]="lang.id">{{ lang.lib }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-select
        appearance="outline"
        formControlName="country_id"
        tabindex="2"
        placeholder="Select a country"
        i18n-placeholder="@@form.select.country"
        required>
        <mat-option
          *ngFor="let country of countries"
          [value]="country.id">
          <span i18n="@@form.select.countries">
            {country.lib, select,
              Brazil {Brazil}
              Canada {Canada}
              France {France}
              Belgium {Belgium}
              United Kingdom {United Kingdom}
              United States {United States}
              Mexico {Mexico}
            }
          </span>
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div> -->
  <div class="text-center">
    <button mat-flat-button
            class="btn btn--primary mt20 mb20"
            [disabled]="form.invalid"
            type="submit"
            tabindex="3"
            i18n="@@global.btn.submit">
      Submit
    </button>
  </div>
</form>
