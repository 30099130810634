import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable, LOCALE_ID, inject } from "@angular/core";
import { AuthFacade } from "auth";
import { fetchAsHttpParams } from "auth/src/lib/helpers/fetch-as-http-params";
import * as moment from "moment";
import { Observable, map, tap } from "rxjs";
import { IsoLocale, Languages } from "src/app/domain/lang";
import { PollRepository } from "src/app/domain/repositories/poll.repository";
import { SurveyRepository } from "src/app/domain/repositories/survey.repository";
import { environment } from "src/environments/environment";
import { Poll, PollMapper, PollAnswerMapper } from "yummypets-js-core";

@Injectable({ providedIn: 'root' })
export class PollRepositoryApi implements PollRepository {

    private path = `polls`
    readonly #http = inject(HttpClient);
    readonly authFacade = inject(AuthFacade)
    readonly locale = inject(LOCALE_ID)

    getUrl() {
        return `${environment.api}${this.path}`
    }

    fetchToday(): Observable<Poll | null>{

      let langId: number | undefined

      //TODO: REFACTO (CREATE INTERCEPTOR)
      const isAuthenticated = this.authFacade.isAuthenticated()

      if(isAuthenticated) {
        langId = Languages.getLanguageByCode(this.authFacade.profile()!.csData!.collection['lang'].value as IsoLocale).id
      } else {
        langId = Languages.getLanguageByCode(this.locale as IsoLocale).id
      }
      const today = moment(new Date()).format("DD/MM/YYYY")

        const params = new HttpParams() 
        .append('date', today)
        .append('lang_id', langId.toString())

        return this.#http.get(`${this.getUrl()}`, { params}).pipe(
          map((r:any) => {
            const data  = r.collection[0]
            if(data)
              return new PollMapper().mapFromEntity(Object.assign({}, data.resource,  data._session)) 
            else
              return null
           
        }))
    }
    

    fetchAnswers(pollId: number): Observable<any> {
      return this.#http.get(`${this.getUrl()}/${pollId}/answers`).pipe(
        map((data:any) => data.collection.map((data:any) => new PollAnswerMapper().mapFromEntity(data.resource) ))) // mapper Answer
    }
    
    answer(pollId: number, answerId: number): Observable<any>  {
        // convert to entity
        const params = { answer_id: answerId }
        return this.#http.post(`${this.getUrl()}/${pollId}/answers`, fetchAsHttpParams(params))
    }
   
}