import { createAction, props } from '@ngrx/store'
import { Credentials } from '../../domain/credentials';

const prefix = '[AUTH]';

/**
 * - LOGIN
 */
export const login = createAction(`${prefix} LOGIN`, props<{ credential: Credentials }>());
export const loginSuccess = createAction(`${prefix} LOGIN_SUCCESS`, props<{ profile: any }>()); 
export const loginFail = createAction(`${prefix} LOGIN_FAIL`, props<{ error: any }>()); // {[key: string]: string}

/**
 * - CREATE USER
 */
export const signup = createAction(`${prefix} SIGNUP`, props<{ data: any }>()); // Partial<UserEntity>
export const signupSuccess = createAction(`${prefix} SIGNUP_SUCCESS`, props<{ profile: any }>()); 
export const signupFail = createAction(`${prefix} SIGNUP_ERROR`, props<{ error: any }>());

/**
 * - POPULATE USER
 */
export const load = createAction(`${prefix} LOAD`, props<{ id: number }>());
export const loadSuccess = createAction(`${prefix} LOAD_SUCCESS`, props<{ profile: any }>());
export const loadFail = createAction(`${prefix} LOAD_FAIL`, props<{ error: {[key: string]: string} }>());
export const loadNoUser = createAction(`${prefix} LOAD_NO_USER`);

/**
 * - CHECK AUTH
 */
export const autoLogin = createAction(`${prefix} AUTO_LOGIN`);
export const autoLoginSuccess = createAction(`${prefix} AUTO_LOGIN_SUCCESS`);
export const autoLoginFail = createAction(`${prefix} AUTO_LOGIN_FAIL`);

/**
 * - PURGE USER
 */
export const logout = createAction(`${prefix} PURGE`)

/**
 * - EDIT USER
 */
export const edit = createAction(`${prefix} EDIT`, props<{ data: FormData  }>()); // Partial<UserEntity>
export const editSuccess = createAction(`${prefix} EDIT_SUCCESS`, props<{ user: any }>());
export const editFail = createAction(`${prefix} EDIT_ERROR`, props<{ error: {[key: string]: string} }>());

/**
 * - ERASE USER
 */
export const erase = createAction(`${prefix} ERASE`);
export const eraseSuccess = createAction(`${prefix} ERASE_SUCCESS`, props<{ user: any }>());
export const eraseFail = createAction(`${prefix} ERASE_ERROR`, props<{ error: {[key: string]: string} }>());

/**
 * - CS DATA FULLFILLED
 */
export const updateCsData = createAction(`${prefix} UPDATE_CS_DATA`, props<{ isCsData: boolean }>());

/**
 * - VALIDATE USER
 */
export const validate = createAction(`${prefix} VALIDATE`);
export const validateSuccess = createAction(`${prefix} VALIDATE_SUCCESS`, props<{ user: any }>());
export const validateFail = createAction(`${prefix} VALIDATE_ERROR`, props<{ error: {[key: string]: string} }>());

/**
 * - SWITCH PET / SET CURRENT PET
 */
export const switchPet = createAction(`${prefix} SWITCH_PET`, props<{ petId: number }>());
export const switchPetSuccess = createAction(`${prefix} SWITCH_PET_SUCCESS`, props<{ pet: any }>());
export const switchPetFail = createAction(`${prefix} SWITCH_PET_FAIL`, props<{ error: {[key: string]: string} }>());


export const editDefaultPetid = createAction(`${prefix} EDIT_DEFAULT_PET_ID`, props<{ id: number }>());

/**
 * - RESET ERROR
 */
export const resetError = createAction(`${prefix} RERROR_RESET`);