
export type IsoLang = 'fr' | 'en' | 'es' | 'pt';
export type IsoCountry = 'mx' | 'br' | 'fr' | 'be' | 'es' | 'ca' | 'gb' | 'us';
export type IsoLocale = `${IsoLang}-${IsoCountry}` | IsoLang

export type Country = {
    id: number,
    name: string,
    iso: string,
    code: IsoCountry,
    languages: number[]
}

export type Language = {
    id: number,
    name: string,
    code: IsoLocale,
    url:  IsoLocale
}

export enum LangCodes {
    FR = 'fr',
    EN = 'en',
    ES = 'es',
    PT_BR = 'pt-br',
    ES_MX = 'es-mx'
}

export enum LangIds {
    FR = 1,
    EN = 2,
    PT_BR = 14,
    ES_MX = 15
}

export const LANGUAGES: Language[]  = [
    {id: 1, name: 'Français', code: 'fr', url: 'fr' },
    {id: 2, name: 'English', code: 'en', url: 'en'},
    {id: 3, name: 'Espanol', code: 'es', url: 'es'},
    {id: 14, name: 'pt', code: 'pt-br', url: 'pt-br'},
    {id: 15, name: 'es', code: 'es-mx', url: 'es-mx'},
]

export const COUNTRIES_FOOTER = [
  {id: 79, name: 'Canada', code: 'ca', iso: 'en-ca', languages: 2 },
  {id: 79, name: 'Canada', code: 'ca', iso: 'fr-ca', languages: 1 },
  {id: 119, name: 'France', code: 'fr', iso: 'fr-fr', languages: 1},
  {id: 122, name: 'United Kingdom', code: 'gb', iso: 'en-gb', languages: 2},
  {id: 284, name: 'United States', code: 'us', iso: 'en-us', languages: 2},
  {id: 72, name: 'Brasil', code: 'br', iso: 'pt-br', languages: 14},
  {id: 204, name: 'México', code: 'mx', iso: 'es-mx', languages: 15},
]

export const COUNTRIES: Country[] = [
    {id: 79, name: 'Canada', code: 'ca', iso: 'ca', languages: [2, 1] },
    {id: 119, name: 'France', code: 'fr', iso: 'fr', languages: [1]},
    {id: 122, name: 'United Kingdom', code: 'gb', iso: 'gb', languages: [2]},
    {id: 284, name: 'United States', code: 'us', iso: 'us', languages: [2]},
    {id: 72, name: 'Brasil', code: 'br', iso: 'pt-br', languages: [14]},
    {id: 204, name: 'México', code: 'mx', iso: 'es-mx', languages: [15]},
    //    {id: 61, name: 'Belgium', code: 'be', iso: 'be', languages: [1]},
]

export const COUNTRIES_ALLOWED_BY_LANG: {[iso: string]: number[]}  = {
  'en': [122, 284, 79],
  'fr': [79, 119, 61],
  'pt-br': [72],
  'es-mx': [204],
}

export const COUNTRIES_FR: Country[] = [...COUNTRIES].filter(v => [79, 119, 61].includes(v.id))
export const COUNTRIES_EN: Country[] = [...COUNTRIES].filter(v => [122, 284, 79].includes(v.id))
export const COUNTRIES_PT_BR: Country[] = [...COUNTRIES].filter(v => [72].includes(v.id))
export const COUNTRIES_ES_MX: Country[] = [...COUNTRIES].filter(v => [204].includes(v.id))

// export as module

export function getCountryById(id: number): Country {
  return COUNTRIES.filter(country => country.id === id)[0]
}

export function getCountryByCode(code: IsoCountry): Country {
  return COUNTRIES.filter(country => country.code === code.toLowerCase())[0]
}

export function getCountryByIso(iso: string): any {
  return COUNTRIES_FOOTER.filter(country => country.iso === iso.toLowerCase())[0]
}

export function isAllowedCountry(locale: string, countryId: number): boolean {
  if(!COUNTRIES_ALLOWED_BY_LANG[locale]) 
    return false
  return COUNTRIES_ALLOWED_BY_LANG[locale].includes(countryId) //) ? true : false;
}


// eslint-disable-next-line @typescript-eslint/no-namespace

export namespace Languages { // replace by module
  export function getLanguageById(id: number): Language {
    return LANGUAGES.filter(lang => lang.id === id)[0]
  }

  export function getLanguageByCode(code: IsoLocale): Language {
    return LANGUAGES.filter(lang => lang.code === code.toLowerCase())[0]
  }

  // export function getLanguagesByCountryCode(): Language[] {
  //   return null
  // }

  // export function getLanguagesByCountryId(): Language[] {
  //   return null
  // }
}

export function getLangIsoAllowedByYummypets(iso: IsoLocale) {
   return (LangCodes.ES_MX === iso || LangCodes.PT_BR === iso) ? LangCodes.EN : iso
}
 
// export namespace Languages {
//     export function getLangByIso(iso: string): Language {
//         return LANG_ARRAY_DETAIL.find(lang => lang.iso === iso)
//     }
//     export function getLangById(id: number): Language {
//         return LANG_ARRAY_DETAIL.find(lang => lang.id === id)
//     }
// }


// export enum LANG {
//     FR = 1,
//     EN = 2,
//     'pt-br' = 14,
//     'es-mx' = 15
//   }

//   type Locale = `${Lang}-${Country}`
  
//   export const DEFAULT_LANG = 'en'
//   export const LANG_ARRAY = ['fr', 'en', 'es-mx', 'pt-br']
//   export const LANG_ARRAY_DETAIL = [
//     {id: 1, lib: 'Français', iso: 'fr'},
//     {id: 2, lib: 'English', iso: 'en'},
//     {id: 14, lib: 'Português', iso: 'pt-br'},
//     {id: 15, lib: 'Español', iso: 'es-mx'},
//   ]
//   export const YMP_LANGS = {
//     FR: 'fr',
//     EN: 'en',
//     ES: 'es',
//     PT_BR: 'pt-br',
//     ES_MX: 'es-mx'
//   }
  
