
// ADD TO CORE LIBRARY

export function extractResource(json: any) {  // JSON OBJECT
    if(!json._session) 
        return json.resource
    return Object.assign({}, json.resource, json._session)
}

export function  extractCollection(json: any) { // JSON OBJECT
    return json.collection
  }

export function extractResourceInCollection(json: any) {  // JSON OBJECT
    return json.collection.map((item: any) => extractResource(item))
}
    
