import { Component, OnInit } from '@angular/core'
import { trigger, state, style, animate, transition } from '@angular/animations'
import { NotifyItem, NotifyEventType, NotifyEvent } from './notify.utils'
import { NotifyService } from './notify.service'
import { NotifyConfig } from './notify.config'


@Component({
  selector: 'yp-ui-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss'],
  animations: [
    trigger('notify', [
      state('card', style({transform: 'translateY(0)', opacity: 1})),
      transition('void => *', [
        style({transform: 'translateY(-30%)', opacity: 0}),
        animate(100)
      ]),
      transition('* => void', [
        animate(100, style({transform: 'translateY(-30%)', opacity: 0}))
      ])
    ]),
  ]
})
export class NotifyComponent implements OnInit {

  notificationItems: NotifyItem[] = []

  constructor(private notifyService: NotifyService, private notifyConfig: NotifyConfig) { }

  ngOnInit() {
    this.notifyService.notifyEvents
      // NO NEED TO DESTROY
      .subscribe((notif: NotifyEvent) => {
        switch (notif.type) {
          case NotifyEventType.ADD:
            this.add(notif.value)
          break
          case NotifyEventType.PURGE:
            this.purge(notif.value)
          break
          case NotifyEventType.PURGE_ALL:
            this.purgeAll()
          break
        }
      })
  }

  add(notif: NotifyItem) {
    if (this.notificationItems.length >= this.notifyConfig.limit) {
       this.notificationItems.shift()
    }

    this.notificationItems.push(notif)
    if (notif.timeout) this._setTimeout(notif)
  }

  purge(id: number) {

    this.notificationItems.forEach((value: any, key: number) => {

      if ( value.id === id ) {

        if (value.onRemove) value.onRemove.call(this, value)
        this.notificationItems.splice(key, 1)
      }
    })
  }

  purgeAll() {
    this.notificationItems.forEach((value: any, key: number) => {
      if (value.onRemove) value.onRemove.call(this, value)
    })
    this.notificationItems = []
  }

  close(id: number) {
    this.purge(id)
  }

  private _setTimeout(notif: NotifyItem) {
    window.setTimeout(() => {
      this.purge(notif.id)
    }, notif.timeout)
  }

}
