import { HttpParams } from "@angular/common/http";
import { WebHttpUrlEncodingCodec } from "./encoder";


export function urlEncoreArray(httpParams:HttpParams, key:string, value:Array<any>){
    value.forEach(value => {
        httpParams = httpParams.append(key, value);
    });
    return httpParams
}

/**
 * - https://github.com/angular/angular/issues/11058 => bug + convert as whitespace
 */
export function fetchAsHttpParams(params: any): HttpParams {

    return Object.getOwnPropertyNames(params)
    .reduce((p, key) => {
        if(key.includes('[]') && Array.isArray(params[key]) ) {
            return urlEncoreArray(p, key, params[key])
        }
        return p.set(key, params[key])
    }, new HttpParams({ encoder: new WebHttpUrlEncodingCodec() }));
}