import { Route } from '@angular/router';
import { LandingComponent } from './pages/landing/landing.component';
import { surveyResolver } from './pages/surveys/resolvers/survey.resolver';
import { userResolver } from './core/resolvers/user.resolver';
import { csGuard } from './core/guards/cs.guard';
import { questionResolver } from './pages/question/resolvers/question.resolver';
import { locationGuard } from './core/guards/location.guard';

export const appRoutes: Route[] = [
    {
        path: '', 
        pathMatch: 'full',
        component: LandingComponent,
    },
    {
        path: 'panel', 
        loadComponent: () => import('./pages/panel/panel.component').then(mod => mod.PanelComponent)
    },
    {
        path: 'surveys', 
        loadComponent: () => import('./pages/surveys/surveys/surveys.component').then(mod => mod.SurveysComponent)
    },
    {
        path: 'shelters', 
        loadComponent: () => import('./pages/others/shelters/shelters.component').then(mod => mod.SheltersComponent)
    },
    {
        path: 'surveys/:id', 
        resolve: { survey: surveyResolver },
        loadComponent: () => import('./pages/surveys/survey-detail/survey-detail.component').then(mod => mod.SurveyDetailComponent)
    },
    {
        path: 'dashboard', 
        canActivate: [locationGuard, csGuard],
        resolve: { user: userResolver },
        loadComponent: () => import('./pages/dashboard/dashboard.component').then(mod => mod.DashboardComponent)
    },
    {   
        path: 'board', 
        canActivate: [locationGuard],
        loadChildren: () => import('./pages/board/board.routes')},
    {
        path: 'login', 
        loadComponent: () => import('./pages/registration/signin/signin.component').then(mod => mod.SigninComponent)
    },
    {
        path: 'signup', 
        canActivate: [locationGuard],
        loadComponent: () => import('./pages/registration/signup/signup.component').then(mod => mod.SignupComponent)
    },
    {
        path: 'password-forgotten', 
        loadComponent: () => import('./pages/registration/pwd-forgotten/pwd-forgotten.component').then(mod => mod.PwdForgottenComponent)
    },
        {
        path: 'password-reset', 
        loadComponent: () => import('./pages/registration/pwd-reset/pwd-reset.component').then(mod => mod.PwdResetComponent)
    },
    {
        path: 'question-of-the-day',
        resolve: { poll: questionResolver },
        loadComponent: () => import('./pages/question/question.component').then(mod => mod.QuestionComponent)
    },
    {
        path: 'legal',
        loadComponent: () =>  import('./pages/others/legals/legals.component').then(mod => mod.LegalsComponent)
    },
    {
        path: 'privacy',
        loadComponent: () =>  import('./pages/others/privacy/privacy.component').then(mod => mod.PrivacyComponent)
    },
    {
        path: 'cookies',
        loadComponent: () =>  import('./pages/others/cookies/cookies.component').then(mod => mod.CookiesComponent)
    },
      {
        path: 'success',
        loadComponent: () =>  import('./pages/others/success/success.component').then(mod => mod.SuccessComponent)
    },
    {
        path: 'disqualification',
        loadComponent: () =>  import('./pages/others/disqualification/disqualification.component').then(mod => mod.DisqualificationComponent)
    },
    {   
        path: '404', 
        loadComponent: () => import('./pages/others/not-found/not-found.component').then(mod => mod.NotFoundComponent)
    },
    // { path: '**', redirectTo: 'dashboard', pathMatch: 'full' }
    {path: '**', redirectTo: 'dashboard'}

  
];


