import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { ACLService, AuthFacade, PetsFacade, Roles, edit } from 'auth';
import { DialogService } from '../dialogs/dialog.service';
import { EXPLORER_ALLOWED_PET_TYPE } from 'src/app/contants';
import { LanguageService } from '../services/language.service';
import { map, skipWhile, zip } from 'rxjs';
import { toObservable } from '@angular/core/rxjs-interop';
import { ScrollToTop } from '../utils';

export const csGuard: CanActivateFn = (route, state) => {

    let currentRole = inject(ACLService).currentRole
    const aclService = inject(ACLService)
    const petFacade = inject(PetsFacade)
    const authFacade = inject(AuthFacade)
    const dialogService = inject(DialogService)
    const router: Router = inject(Router)

    const sources = [
        authFacade.loaded$.pipe(
            skipWhile((isLoaded) => isLoaded === false),
        ),
        toObservable(petFacade.loaded).pipe(
            skipWhile((isLoaded) => isLoaded === false),
        ),
        toObservable(aclService.currentRole).pipe(
            skipWhile((role: Roles) => role === Roles.Guest),
        )
    ]

    return zip(...sources).pipe(
    
        map(() => { 

             /**
             * - User (date_born missing)
             */
             if (currentRole() === Roles.UserIncomplete) {
                const params = {
                    picture: 'https://yp-explorer.s3.amazonaws.com/v2/illus_broken-leash.svg',
                    title: $localize`:@@dialog.complete-user.title:Complete your profile`,
                    desc: $localize`:@@dialog.complete-user.desc:One or more pieces of information concerning you are missing. Please take a few moments to check your profile.`
                }

                dialogService.displayInformation(params).subscribe()
                router.navigateByUrl('/board/edit-user')
                return false
            }

            /**
             * - Pet without CS Only
             */
            if (currentRole() === Roles.PetIncomplete) {
                const params = {
                    picture: 'https://yp-explorer.s3.amazonaws.com/v2/illus_broken-leash.svg',
                    title: $localize`:@@dialog.complete-pet.title:Complete your profile`,
                    desc: $localize`:@@dialog.complete-pet.desc:One or more pieces of information concerning ${petFacade.currentPet()?.pseudo}:INTERPOLATION: are missing. Please take a few moments to check your profile.`
                }
                
                dialogService.displayInformation(params).subscribe()
                router.navigateByUrl(`/board/pets/${petFacade.currentPet()!.id}/edit`)
                return false
            }

            /**
             * - Pet Expired
             */
            if (currentRole() === Roles.PetCSExpired) {
                const params = {
                    picture: 'https://yp-explorer.s3.amazonaws.com/v2/illus_broken-leash.svg',
                    title: $localize`:@@dialog.expired-pet.title:Data updates`,
                    desc: $localize`:@@dialog.expired-pet.desc:The information concerning ${petFacade.currentPet()?.pseudo}:INTERPOLATION: is over a year old. As part of our quality assurance process, we will ask you to verify your information again. Please take a few moments to complete this operation.`
                }

                dialogService.displayInformation(params).subscribe()
                router.navigateByUrl('/board/add-pet', { state: {edit: true }})
                //router.navigateByUrl(`/board/pets/${petFacade.currentPet()!.id}/edit`)
                return false
            }

            /**
             * - User Expired
             */
            if (currentRole() === Roles.UserCSExpired) {
                const params = {
                    picture: 'https://yp-explorer.s3.amazonaws.com/v2/illus_broken-leash.svg',
                    title: $localize`:@@dialog.expired-user.title:Data updates`,
                    desc: $localize`:@@dialog.expired-user.desc:The information concerning you is over a year old. As part of our quality assurance process, we will ask you to verify your information again. Please take a few moments to complete this operation.`
                }

                dialogService.displayInformation(params).subscribe()
                router.navigateByUrl(`/board/edit-user`)
                return false
            }

            /**
             * - User without CS Only
             */
            if (currentRole() === Roles.User) {
                const params = {
                    picture: 'https://yp-explorer.s3.amazonaws.com/v2/illus_broken-leash.svg',
                    title: $localize`:@@dialog.complete-user.title:Complete your profile`,
                    desc: $localize`:@@dialog.complete-user.desc:One or more pieces of information concerning you are missing. Please take a few moments to check your profile.`
                }

                dialogService.displayInformation(params).subscribe()
                router.navigateByUrl('/board/edit-user')
                return false
            }

            /**
             * - User (No CS) + Pet (No CS)
             */
            if (currentRole() === Roles.Pet) {

                const params = {
                    picture: 'https://yp-explorer.s3.amazonaws.com/v2/illus_broken-leash.svg',
                    title: $localize`:@@dialog.complete-user.title:Complete your profile`,
                    desc: $localize`:@@dialog.complete-user.desc:One or more pieces of information concerning you are missing. Please take a few moments to check your profile.`,
                }

                dialogService.displayInformation(params).subscribe()
                router.navigateByUrl('/board/edit-user')
                return false
            }

            /**
             * - UserCS + No Pet
             */
            if (currentRole() === Roles.UserCS && !petFacade.currentPet()) {

                const params = {
                    picture: 'https://yp-explorer.s3.amazonaws.com/v2/illus_broken-leash.svg',
                    title: $localize`:@@dialog.complete-pet.title:Complete your profile`,
                    desc: $localize`:@@dialog.no-pet.desc:You must have a dog or cat to participate in surveys.`,
                }

                dialogService.displayInformation(params).subscribe()
                router.navigateByUrl('/board/add-pet')
                return false
            }

            /**
             * - UserCS + Pet ALLOWED
             */
            const isAllowedType = EXPLORER_ALLOWED_PET_TYPE.includes(petFacade.currentPet()!.petType!.id)

            if (currentRole() === Roles.UserCS 
                && petFacade.currentPet() 
                && isAllowedType) {

                const params = {
                    picture: 'https://yp-explorer.s3.amazonaws.com/v2/illus_woman-dog.svg',
                    title: $localize`:@@dialog.complete-user.title:Complete your profile`,
                    desc: $localize`:@@dialog.complete-pet.desc:One or more pieces of information concerning ${petFacade.currentPet()?.pseudo}:INTERPOLATION: are missing. Please take a few moments to check your profile.`,
                }

                dialogService.displayInformation(params).subscribe()
                const id = petFacade.currentPet()!.id
                router.navigateByUrl('/board/add-pet', { state: {edit: true }})
                return false
            }

            /**
             * - UserCS + Pet Not ALLOWED
             * - case treated 
             */
            if (currentRole() === Roles.UserCS 
            && petFacade.currentPet() 
            && !isAllowedType) {

                const params = {
                    picture: 'https://yp-explorer.s3.amazonaws.com/v2/illus_woman-dog.svg',
                    title: $localize`:@@dialog.unauthorized-pet.title:This type of animal is not authorized`,
                    desc: $localize`:@@dialog.unauthorized-pet.desc:You must have a dog or cat to participate in surveys`,
                }

                dialogService.displayInformation(params).subscribe()
                router.navigateByUrl('/board/pets')
                return false
            }

            return true;    

        })
    )


};
