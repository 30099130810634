
// ADD TO CORE

import { LangCodes } from "./domain/lang"

export const COUNTRY_COOKIE = 'COUNTRY'
export const COUNTRY_URL_PARAM = 'countryIso'

export const API_ERRORS = {
    accountValidation 			: 'UserValidationRequired',
    accountAlreadyValidate 	    : 'EmailAlreadyValidated',
    invalidDomainEmail 			: "invalid_domain",
    accountBlocked 			    : 'BlockedUser'
}

export const USER_CONSUMER_LIBS = [
    'cs.career',
    'cs.children',
    'cs.education',
    'cs.employment',
    'cs.income',
    'cs.living',
    'cs.location',
    'cs.marital_status'
]
  
export const PET_CONSUMER_LIBS = [
    'cs.breed',
    'cs.dry_brand',
    'cs.food_type',
    'cs.health_issues',
    'cs.litter_brand',
    'cs.shopping_place',
    'cs.sterilized',
    'cs.treats_brand',
    'cs.wet_brand'
]

export const EXPLORER_ALLOWED_PET_TYPE = [1,2]

export const EXPLORER_ORIGIN = 173

export const FOOD_BRAND_KEYS_TABLE = [
    'is_dry_food',
    'is_wet_food',  
    'is_treat_food',
]


// DESTROY
export const ID_FIELD_BRAND_TABLE: { [key: number]: string } = { 
    110 : 'cs.dry_brand',
    111 : 'cs.wet_brand',
    112 : 'cs.treats_brand'
}

export const KEYS_FIELD_BRAND_TABLE: {[key: string]: string } = {
    'is_dry_food': 'cs.dry_brand',
    'is_wet_food': 'cs.wet_brand',
    'is_treat_food': 'cs.treats_brand'
}

export const SLUG_FIELD_BRAND_TABLE: {[key: string]: string } = { 
    'dry' : 'cs.dry_brand',
    'wet' : 'cs.wet_brand',
    'treats' : 'cs.treats_brand'
}

export const BRAND_ID_CATEGORY_TABLE : { [key: number]: number } = {
    111: 1, // wet
    110: 2, // dry 
    112: 3 // treats
}

export const SLUG_BRAND_CATEGORY_TABLE : { [key: string]: number } = {
    'wet': 1, // wet
    'dry': 2, // dry 
    'treats': 3 // treats
}

export const BRAND_KEY_CATEGORY_TABLE: { [key: string]: number } = {
    'cs.wet_brand': 1, // wet
    'cs.dry_brand': 2, // dry 
    'cs.treats_brand': 3, // treats
    'cs.litter_brand': 7 // litter
}

export const CLOUDFRONT_URLS = {
    explorer: 'https://d2xec21l9srv8z.cloudfront.net',
    explorer_v2: 'https://d2xec21l9srv8z.cloudfront.net/v2/'
}

export const SEO_SHARE_IMAGE = 'https://explore.yummypets.com/en/share_explorer.jpg'

export const LANG_ARRAY_DETAIL = [
    {id: 1, lib: 'Français', iso: 'fr'},
    {id: 2, lib: 'English', iso: 'en'},
    {id: 14, lib: 'Português', iso: 'pt-br'},
    {id: 15, lib: 'Español', iso: 'es-mx'},
]

export const COUNTRIES = [
    {id: 79, lib: 'Canada', iso: 'ca', url: Lang.EN },
    {id: 119, lib: 'France', iso: 'fr', url: Lang.FR},
    {id: 122, lib: 'United Kingdom', iso: 'gb', url: Lang.EN},
    {id: 284, lib: 'United States', iso: 'us', url: Lang.EN},
    {id: 72, lib: 'Brazil', iso: 'pt-br', code: '', url: Lang.PT_BR},
    {id: 204, lib: 'Mexico', iso: 'es-mx',  url: Lang.ES_MX},
    //  {id: 61, lib: 'Belgium', iso: 'be',  url: Lang.FR},
]

export enum PetTypesId {
    DOG = 1,
    CAT = 2,
    REPTILE = 6,
    FISH = 7,
    BIRD = 8,
    RODENT = 9,
    HORSE = 10,
    INSECT = 12,
    OTHER = 13
}

export enum BrandCategories {
    LITTER = 7
}  

export const enum Lang {
    FR= 'fr',
    EN= 'en',
    ES= 'es',
    PT_BR= 'pt-br',
    ES_MX= 'es-mx'
}

export enum ResponsiveBreakpoints {
    MOBILE = 770,
    TABLET = 960
}
  
export enum ResponsiveDevice {
    mobile = 'mobile',
    tablet = 'tablet',
    desktop = 'desktop',
    tablet_desktop = 'tablet&desktop'
}

export enum ConsumerLib {
    MARITAL =  "marital_status",
    CHILDREN = "children",
    INCOME = "Income",
    EDUCATION = "education",
    EMPLOYMENT = "employment",
    LIVING = "living",
    LOCATION = "location",
    REGION = "region",
    HOUSEHOLD = "household",
    CAREER = "career",
    HEALTH = "health_issues",
    SHOPPING = "shopping_place",
    BREED = "breed",
    ALIMENTATION = "food_type",
    VETERINARY = "veterinary",
    WEIGHT_DOG = "dog_weight",
    WEIGHT_CAT = "cat_weight",
}

type Image = {
    filename: string,
    large: string,
    medium: string,
    small: string,
    source: string,
    thumbor: string
}

export const OTHER_BRANDS_IMAGES: { [key: string]: Image } = { 
    "fr" : {
        filename: "79_fr_logo_large.jpg",
        large: "https://d235cq462zvqv9.cloudfront.net/brands/79_fr_logo_large.jpg",
        medium: "https://d235cq462zvqv9.cloudfront.net/brands/79_fr_logo_medium.jpg",
        small: "https://d235cq462zvqv9.cloudfront.net/brands/79_fr_logo_small.jpg",
        source: "https://d235cq462zvqv9.cloudfront.net/brands/79_fr_logo.jpg",
        thumbor: "https://d16i69dw55fnlu.cloudfront.net/"
    },
    "en" : {
        filename: "79_en_logo_large.jpg",
        large: "https://d235cq462zvqv9.cloudfront.net/brands/79_en_logo_large.jpg",
        medium: "https://d235cq462zvqv9.cloudfront.net/brands/79_en_logo_medium.jpg",
        small: "https://d235cq462zvqv9.cloudfront.net/brands/79_en_logo_small.jpg",
        source: "https://d235cq462zvqv9.cloudfront.net/brands/79_en_logo.jpg",
        thumbor: "https://d16i69dw55fnlu.cloudfront.net/"
    },
    "pt-br" : {
        filename: "79_pt-br_logo_large.jpg",
        large: "https://d235cq462zvqv9.cloudfront.net/brands/79_pt-br_logo_large.jpg",
        medium: "https://d235cq462zvqv9.cloudfront.net/brands/79_pt-br_logo_medium.jpg",
        small: "https://d235cq462zvqv9.cloudfront.net/brands/79_pt-br_logo_small.jpg",
        source: "https://d235cq462zvqv9.cloudfront.net/brands/79_pt-br_logo.jpg",
        thumbor: "https://d16i69dw55fnlu.cloudfront.net/"
    },
    "es-mx" : {
        filename: "79_es-mx_logo_large.jpg",
        large: "https://d235cq462zvqv9.cloudfront.net/brands/79_es-mx_logo_large.jpg",
        medium: "https://d235cq462zvqv9.cloudfront.net/brands/79_es-mx_logo_medium.jpg",
        small: "https://d235cq462zvqv9.cloudfront.net/brands/79_es-mx_logo_small.jpg",
        source: "https://d235cq462zvqv9.cloudfront.net/brands/79_es-mx_logo.jpg",
        thumbor: "https://d16i69dw55fnlu.cloudfront.net/"
    }
}