import { Injectable, inject , } from "@angular/core";
import *  as moment from "moment";
import { HttpClient } from '@angular/common/http';
import { Observable, concatAll, flatMap, forkJoin, map, tap, zip } from "rxjs";
import { AuthRepository } from "../domain/auth.repository";
import { AUTH_CONFIG_TOKEN } from "../auth.config";
import { JSONObject } from 'yummypets-js-core'
import { fetchAsHttpParams } from "../helpers/fetch-as-http-params";
import { Credentials } from "../domain/credentials";
import { User } from "../domain/user.model";
import { extractResource, extractResourceInCollection } from "../helpers/extractors";
import { UserEntity } from "./user.entity";
import { UserMapper } from "./user.mapper";
import { consumerExtractor } from "../domain/user-consumer.utils";
import { ConsumerMapper } from "./data-consumer.mapper";
import { ConsumerData } from "../domain/consumer-data.model";
import { Pet } from "../domain/pet.model";
import { PetMapper } from "./pet.mapper";
import { PetIRepository } from "./pet-profile.repository.api";
import { ExpirationService } from "../services/expiration.service";

@Injectable()
export class AuthIRepository implements AuthRepository {

    readonly path = `users`;
    readonly http = inject(HttpClient);
    readonly config = inject(AUTH_CONFIG_TOKEN);
    readonly #petsRepository = inject(PetIRepository)
    readonly #expirationService = inject(ExpirationService)

    /*
    *https://medium.com/swlh/behavioral-design-pattern-strategy-5e9d4df1898a
    */ 

    private getUrl() {
        return `${this.config.api}${this.path}`;
    }

    login(credential: Credentials): Observable<User> {

        const params = fetchAsHttpParams(credential)
        return this.http.post(`${this.config.api}connect`, params)
        .pipe(map((resp: JSONObject) => this.transformAsUser(resp['_session'])))
    }

    loginWithToken(token: string): Observable<User> {

        const params = fetchAsHttpParams({token})
        return this.http.post(`${this.config.api}connect`, params)
        .pipe(map((resp: JSONObject) => this.transformAsUser(resp)))
    }

    logout() {
        throw new Error("Method not implemented.");
    }

    /**
     * @returns {
     *  collection, 
     *  isComplete, 
     *  uncomplete, 
     *  lastUpdated,
     *  isExpired
     * }
     */
    getConsumerData(id: number): Observable<any> {

        // const sources$: any[] = [
        //     this.http.get(`${this.getUrl()}/${id}/consumer/datas`),
        //     this.getConsumerDataExpiration()
        // ]

        return this.http.get(`${this.getUrl()}/${id}/consumer/datas`).pipe(
            map((user: JSONObject) => {

                
            // TODO: CREATE METHOD
            let collection = {} as [key: string, value: ConsumerData]
            user.collection.map((data:JSONObject) => {
                collection[data.resource.type.name] = new ConsumerMapper().mapFromEntity(data.resource)
            })

            let isExpired = false
            let lastUpdatedTime = null

            // TODO: CREATE METHOD
            if(user?.extras?.last_updated_time) {
                const currentDay = moment() 
                const duration = this.#expirationService.getDuration()
                lastUpdatedTime = currentDay.diff(user?.extras?.last_updated_time, 'days')
                isExpired = (lastUpdatedTime > duration.asDays())
            }   

            // TODO: CREATE MAPPER
            return {
                collection,
                isComplete : user?.extras?.completed || false,
                uncomplete : user?.extras?.uncomplete_datas,
                lastUpdated: lastUpdatedTime,
                isExpired
                // TODAY-last_updated > cs_datas_expiration
                //create method
            }
        }))
    }

    /**
     * @returns 'cs_datas_expiraztion' : ISO 8601
     */
    getConsumerDataExpiration() {
        return this.http.get(`${this.config.api}explorer/settings`)
    }

    getCurrentUser(id: number, consumer = false): Observable<any> {

        // eslint-disable-next-line prefer-const
        let sources$: any[] = [
            this.http.get(`${this.getUrl()}/${id}/current`).pipe(
                map(extractResource),
                map((resp: JSONObject) => this.transformAsUser(resp)))
        ];

        if (consumer) {
            const request = this.getConsumerData(id)
            sources$.push(request)
        }

        return forkJoin(sources$).pipe(map((response: any) => {

            // eslint-disable-next-line prefer-const
            let [user, csData] = response

            /** 
             * CREATE PATTERN BUILDER
             */
            if(csData) {
                user.csData = csData
            }
           
            return user
        }))
        
    }

    signup(data: FormData) {

        // data = new UserMapper().mapToEntity(data)
        const params = fetchAsHttpParams(data)
        return this.http.post(`${this.config.api}users`, params).pipe(
            map(extractResource),
            map((resp: JSONObject) => this.transformAsUser(resp)))
    }

    edit(id: number, user: FormData) {

        const params = fetchAsHttpParams(user)
        return this.http.put(`${this.getUrl()}/${id}`, params).pipe(
            map(extractResource),
            map((resp: JSONObject) => this.transformAsUser(resp)))
    }

    updateCurrentPassword(id: number, data: FormData) {
        return this.http.put(`${this.getUrl()}/${id}`, data)
    }

    editLangAndCountry(id: number, lang: string, csLang: string, countryId: number) {

        const params = {
            'cs.lang': csLang,
            country_id: countryId,
            lang
        }
        return this.http.put(`${this.getUrl()}/${id}`, fetchAsHttpParams(params))
    }

    editCsLangAndCountry(id: number, csLang: string, countryId: number) {
        const params = {
            'cs.lang': csLang,
            country_id: countryId,
        }
        return this.http.put(`${this.getUrl()}/${id}`, fetchAsHttpParams(params))
    }

    editPassword(data: FormData) {
        return this.http.put(`${this.config.api}reset_password`, data)
    }

    resetPassword(data: any): Observable<any> {
        return this.http.post(`${this.config.api}reset_password`,fetchAsHttpParams(data))
    }

    validateAccount(id :number) {
        return this.http.put(`${this.getUrl()}/${id}/validate_account`, {})
    }

   

    regenerateValidation(id:number) { 
        return this.http.post(`${this.getUrl()}/${id}/regenerate_validation`, {})
    }

    addPet(id: number, data: FormData): Observable<Pet> {
        const params = fetchAsHttpParams(data)
        return this.http.post(`${this.getUrl()}/${id}/pets`, params).pipe(
            map(extractResource),
            map((resp: JSONObject) => new PetMapper().mapFromEntity(resp)))
    }

    switchPet(userId: number, petId: number): Observable<Pet> {
        const params = fetchAsHttpParams({pet_id: petId})
        return this.http.put(`${this.getUrl()}/${userId}/currentPet/${petId}`, params)
        .pipe(map((res:any) => Object.assign(res.resource, res._session)))
    }

    fetchPets(id: number, filters?: FormData): Observable<Pet[]> {

        const params = (filters)? fetchAsHttpParams(filters) : {}
        return this.http.get(`${this.getUrl()}/${id}/pets`, { params })
        .pipe(
            map(extractResourceInCollection),
            map((resp: JSONObject) => resp.map((resp:any) => this.transformAsPet(resp)))
        )
    }

    fetchPetsWithCs(id: number, filters?: FormData): Observable<Pet[]> {

        const params = (filters)? fetchAsHttpParams(filters) : {}
        return this.http.get(`${this.getUrl()}/${id}/pets`, { params }).pipe(
            map(extractResourceInCollection),
            flatMap((collection : any) => // DEFINE ENTITY
                forkJoin(  // DEFINE ENTITY
                    collection.map((pet : any) => this.#petsRepository.fetchPet(pet.id, true)) as Pet[]
                ))
        )
    }

    delete(id: number) {
        return this.http.delete(`${this.getUrl()}/${id}`);
    }

    fetchSurveys(id: number, filters = {}): Observable<any> {

        const params = (filters)? fetchAsHttpParams(filters) : {}
        return this.http.get(`${this.getUrl()}/${id}/surveys`, { params })
        .pipe(map(extractResourceInCollection))
    }
 
    private transformAsUser(json: UserEntity): User {
        return new UserMapper().mapFromEntity(json)
    }

    private transformAsPet(json: JSONObject): Pet {
        return new PetMapper().mapFromEntity(json)
    }
    
}

