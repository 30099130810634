import { Injectable} from '@angular/core';
import { StorageRepository } from '../domain/storage.repository';
import { StorageOptions } from '../domain/storage.model';

export function isPresent(obj: any): boolean {
  return obj !== undefined && obj !== null;
}

export function isString(obj: any): obj is string {
  return typeof obj === 'string';
}


const COOKIE_MAX_SIZE = 4096

@Injectable({
  providedIn: 'root',
})
export class DefaultStorageService implements StorageRepository {

  options: StorageOptions = {};

  // constructor(private _options: CookieOptions) {
  //   this.options = _options || {};
  // }

  /**
   * per http://www.ietf.org/rfc/rfc2109.txt browser must allow at minimum:
   * 300 cookies
   * 20 cookies per unique domain
   * 4096 bytes per cookie
   */
  setItem(key: string, value: string, options?: StorageOptions) {

    const opts: StorageOptions = this.mergeOptions(this.options, options);
    //let val:string = (isString(value))? <string>value : JSON.stringify(value)
    let expires: any = opts.expires;

    if (isString(expires)) {
      expires = new Date(expires);
    }

    let str = encodeURIComponent(key) + '=' + encodeURIComponent(value);
    str += opts.path      ? ';path=' + opts.path : '';
    str += opts.domain    ? ';domain=' + opts.domain : '';
    str += expires        ? ';expires=' + expires.toUTCString() : '';
    str += opts.secure    ? ';secure' : '';
    //str += opts.httpOnly  ? '; HttpOnly' : '';

    const cookieLength = str.length + 1;
    if (cookieLength > COOKIE_MAX_SIZE) {
      console.log(`Cookie '${name}' possibly not set or overflowed because it was too
      large (${cookieLength} > 4096 bytes)!`);
    }
    if(this.hasItem(key)) {
      this.remove(key)
    }
    document.cookie = str
  }

  getItem(key: string): string  {
    // if (!key) { return '' }
    return decodeURIComponent(document.cookie.replace(new RegExp("(?:(?:^|.*;)\\s*" + encodeURIComponent(key).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=\\s*([^;]*).*$)|^.*$"), "$1"))
  }

  /**
   * From https://developer.mozilla.org/fr/docs/Web/API/Document/cookie/Simple_document.cookie_framework
   */
  hasItem(key:string) {
    if (!key || /^(?:expires|max\-age|path|domain|secure)$/i.test(key)) { return false; }
    return (new RegExp("(?:^|;\\s*)" + encodeURIComponent(key).replace(/[\-\.\+\*]/g, "\\$&") + "\\s*\\=")).test(document.cookie);
  }

  remove(key: string, options: StorageOptions = {}) {

    const opts: StorageOptions = this.mergeOptions(this.options, options);

    if (!this.hasItem(key)) { return false; }
    document.cookie = encodeURIComponent(key) + "=; expires=Thu, 01 Jan 1970 00:00:00 GMT" + (opts.domain ? "; domain=" + opts.domain : "") + (opts.path ? "; path=" + opts.path : "") + (opts.secure ? "; secure" : "");
    return true;
  }

  private mergeOptions(currentOptions: StorageOptions, newOptions?: StorageOptions): StorageOptions {

    if (!newOptions) {
      return currentOptions;
    }

    return {
      path: isPresent(newOptions.path)        ? newOptions.path     : currentOptions.path,
      domain: isPresent(newOptions.domain)    ? newOptions.domain   : currentOptions.domain,
      expires: isPresent(newOptions.expires)  ? newOptions.expires  : currentOptions.expires,
      secure: isPresent(newOptions.secure)    ? newOptions.secure   : currentOptions.secure,
      httpOnly: isPresent(newOptions.httpOnly)    ? newOptions.httpOnly   : currentOptions.httpOnly,
    };

  }
}
