import { Component, ElementRef, OnInit, Renderer2, ViewChild, inject } from '@angular/core'
import { CommonModule } from '@angular/common'
import { NavbarLinksComponent } from '../navbar-links/navbar-links.component'
import { NavbarAuthComponent } from '../navbar-auth/navbar-auth.component'
import { CDN } from 'src/app/core/constants'
import { AuthFacade } from 'auth'
import { Router } from '@angular/router'
import { ResponsiveBreakpoints } from 'src/app/contants'

@Component({
  selector: 'ex-main-header',
  standalone: true,
  imports: [
    CommonModule, 
    NavbarLinksComponent,
    NavbarAuthComponent,
  ],
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent implements OnInit {

  isMenuVisible = false
  readonly logoSVG = `${CDN.url}logo_explorer-header.svg`
   mobileClickListener: any
  @ViewChild('mobileHeader', {static: true}) private mobileHeader!: ElementRef;
  
  isAuthenticated = inject(AuthFacade).isAuthenticated
  router = inject(Router)
  renderer = inject(Renderer2)


  ngOnInit() {
    if (window.innerWidth < ResponsiveBreakpoints.TABLET) {
      this.mobileClickListener = this.renderer.listen(this.mobileHeader.nativeElement, 'click', (e:any) => {
        // all the links but not submenu open/close button
       //  if (e.target.nodeName === 'A' && !e.target.getAttribute('data-toggle')) {
          this.toggleNav()
        //}
      })
    }
  }

  redirectToPage() {
    if(this.isAuthenticated()) {
      this.router.navigateByUrl('/dashboard')
      return
    }
    this.router.navigateByUrl('/')
  }

  toggleNav(state = false) {
    this.isMenuVisible = state
  }

  ngOnDestroy() {
    if (this.mobileClickListener) {
      this.mobileClickListener()
    }
  }

}
