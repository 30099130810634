import { Injectable, inject } from '@angular/core'
import { select, Store, Action } from '@ngrx/store'

import * as PetsActions from './pets.actions'
import * as PetsFeature from './pets.reducer'
import * as PetsSelectors from './pets.selectors'

@Injectable({ providedIn: 'root'})
export class PetsFacade {
  private readonly store = inject(Store)

  readonly currentPet = this.store.selectSignal(PetsSelectors.currentPet)
  readonly pets = this.store.selectSignal(PetsSelectors.pets)
  readonly isCsDataFullfilled = this.store.selectSignal(PetsSelectors.isCsDataFullfilled)
  readonly isCsDataExpired = this.store.selectSignal(PetsSelectors.isCsDataExpired)
  readonly isDataFullfilled = this.store.selectSignal(PetsSelectors.isDataFullfilled)
  readonly isLoading = this.store.selectSignal(PetsSelectors.isLoadingPet)
  readonly error = this.store.selectSignal(PetsSelectors.errorPet)
  readonly loaded = this.store.selectSignal(PetsSelectors.loadedPet)

  /**
   * @deprecated Use Signals instead of Observables
   */
  currentPet$ = this.store.pipe(select(PetsSelectors.currentPet))
  
  refreshCurrentPet(id: number) {
    this.store.dispatch(PetsActions.loadPet({ id }))
  }

  loadPets(userId: number) {
    this.store.dispatch(PetsActions.loadPets({ userId }))
  }

  addPet(userId: number, pet: any) {
    this.store.dispatch(PetsActions.addPet({ userId, pet }))
  }

  editPet(data: any) {
    this.store.dispatch(PetsActions.editPet({ pet: data}))
  }

  deletePet(petId: number) {
    // this.store.dispatch(PetsActions.deletePet({ petId }))
  }

  purge() {
    
    this.store.dispatch(PetsActions.purge())
  }

  resetError() {
    this.store.dispatch(PetsActions.resetPetError())
  }

}
