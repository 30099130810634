import { Observable, map, of } from "rxjs";
import { SurveyRepository } from "../../domain/repositories/survey.repository";
import { Survey } from "../../domain/survey.model";
import { Injectable, inject } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { SurveyMapper } from "src/app/domain/survey.mapper";


@Injectable({ providedIn: 'root' })
export class SurveyRepositoryApi implements Partial<SurveyRepository> {

    private path = `surveys`
    readonly #http = inject(HttpClient);

    getUrl() {
     return `${environment.api}${this.path}`
    }

    fetch(id: number) {
        return this.#http.get(`${this.getUrl()}/${id}`).pipe(
          map((res:any) => new SurveyMapper().mapFromEntity({...res.resource, ...res._session})))
    }

    fetchAll(params={}): Observable<Survey[]> {
        return this.#http.get(`${this.getUrl()}`, {params}).pipe(
            map((data:any) => data.collection.map((r:any) => new SurveyMapper().mapFromEntity({...r.resource, ...r._session}))))
    }
    
    fetchStaticSurveys(lang: string): Observable<Survey[]> {
        const url: string = `assets/surveys/${lang}.json`
        return this.#http.get(url).pipe(
            map((res:any) => res['collection'].map((s:any) => new SurveyMapper().mapFromEntity(s)))
        )
    }
}