import { HttpClient } from "@angular/common/http"
import { Injectable, inject } from "@angular/core"
// import { LocationRepository } from "src/app/domain/repositories/location.repository"
import { environment } from "src/environments/environment"


@Injectable({ providedIn: 'root' })
export class LocationRepositoryApi  {  // implements LocationRepository

    readonly path = `locations`
    readonly #http = inject(HttpClient)

    getUrl() {
        return `${environment.api}${this.path}`
    }
    
    getCountryFromIP() {
        return this.#http.get(`${this.getUrl()}/country`)
    }

}