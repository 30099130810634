import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpErrorService } from './error.service';

export const errorInterceptor: HttpInterceptorFn = (req, handle) => {

    const errorService = inject(HttpErrorService)
  
    return handle(req).pipe(
      catchError((err) => {
        if (err instanceof HttpErrorResponse) {
          return errorService.handleError(err) as Observable<never>
        } 
        return throwError(() => err);    
      }),
    );
  };
