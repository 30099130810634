import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter, HostBinding } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { LANG_ARRAY_DETAIL } from 'src/app/contants';
import { COUNTRIES_EN, COUNTRIES_ES_MX, COUNTRIES_FR, COUNTRIES_PT_BR, LangCodes } from 'src/app/domain/lang';

@Component({
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    ReactiveFormsModule,
  ],
  selector: 'ex-form-lang-country',
  templateUrl: './form-lang-country.component.html',
  styleUrls: ['./form-lang-country.component.scss'],
  standalone: true
})
export class FormLangCountryComponent implements OnInit {

  form!: UntypedFormGroup
  countries: any[] = []
  readonly langs = LANG_ARRAY_DETAIL

  @Input() lang: any // create interface
  @Output() submitted = new EventEmitter<any>()

  constructor(
    private fb: UntypedFormBuilder,
  ) { }

  ngOnInit(): void {

    this.form = this.fb.group({
      'cs.lang':['', Validators.required],
      country_id: ['', Validators.required],
    })

    this.form.controls['cs.lang'].patchValue(this.lang)
    this.fillLang()
  }

  // DUPLICATION USER FORM
  private fillLang() {
    if(this.form.value['cs.lang']) {
      this.updateListCountries(this.form.value['cs.lang'])
    }
  }

  // DUPLICATION USER FORM
  private updateListCountries(lang: string) {
    switch(lang) {
      case LangCodes.PT_BR:
        this.countries = COUNTRIES_PT_BR
        this.form.controls['country_id'].setValue(COUNTRIES_PT_BR[0].id)
        break;
      case LangCodes.ES_MX:
        this.countries = COUNTRIES_ES_MX
        this.form.controls['country_id'].setValue(COUNTRIES_ES_MX[0].id)
        break;
      case LangCodes.FR:
        this.countries = COUNTRIES_FR
        this.form.controls['country_id'].setErrors({'incorrect': true});
        break;
      default:
        this.countries = COUNTRIES_EN
        this.form.controls['country_id'].setErrors({'incorrect': true});
    }
    this.form.controls['cs.lang'].setValue(lang)
  }

  onChangeLang(event:any) {
    this.updateListCountries(event.value)
  }

  onSubmit() {
    this.submitted.emit(this.form.value)
  }

}
