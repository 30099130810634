import { Component, OnInit, ChangeDetectionStrategy, Inject } from '@angular/core'
import { MAT_DIALOG_DATA, MatDialogModule, MatDialogRef } from '@angular/material/dialog'

@Component({
  standalone: true,
  selector: 'yp-dialog-information',
  templateUrl: './dialog-information.component.html',
  styleUrls: ['./dialog-information.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    MatDialogModule
  ]
})
export class DialogInformationComponent implements OnInit {

  constructor(
    public dialogRef: MatDialogRef<DialogInformationComponent>,
      @Inject(MAT_DIALOG_DATA) public data: {title?: string, desc: string, picture: string}
    ) {}

  ngOnInit() {}

  confirm(answer: boolean) {
    this.dialogRef.close(answer)
  }
}
