import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, LOCALE_ID, OnInit, inject } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDialogModule, MatDialogRef } from '@angular/material/dialog';
import { RouterModule } from '@angular/router';
import { AuthFacade, AuthIRepository } from 'auth';
import { DefaultSessionService } from 'auth/src/lib/services/session.service';
import { COUNTRY_COOKIE } from 'src/app/contants';
import { FormLangCountryComponent } from 'src/app/pages/registration/forms/form-lang-country/form-lang-country.component';

@Component({
  standalone: true,
  selector: 'ex-dialog-lang-country',
  templateUrl: './dialog-lang-country.component.html',
  styleUrls: ['./dialog-lang-country.component.scss'],
  imports: [
    MatDialogModule,
    CommonModule, 
    RouterModule, 
    FormsModule, 
    ReactiveFormsModule,
    FormLangCountryComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DialogLangCountryComponent implements OnInit {

  data: any // TODO: Inrterface

  lang = inject(LOCALE_ID)
  authFacade = inject(AuthFacade)
  authRepository = inject(AuthIRepository)
  sessionService = inject(DefaultSessionService)

  constructor(
    public _dialogRef: MatDialogRef<DialogLangCountryComponent>,
    ) { }

  ngOnInit(): void {
    
    this._dialogRef.disableClose = true;


    // forkJoin([
    //     this._store.select(getCountryId).pipe(take(1)),
    //     this._store.select(getLangId).pipe(take(1))
    //   ]).subscribe(result => {
    //     const [countryId, langId] = result
    //     this.data = {
    //       country: countryId,
    //       lang: langId
    //     }
    //   })
  }

  onSubmit(e: any) {

    if(this.authFacade.isAuthenticated()) {
      const user = this?.authFacade.profile()
      this.authRepository.editCsLangAndCountry(user!.id, e['cs.lang'], e.country_id)
        .subscribe(result => {
          // if locale !== lang 
          // redirect
          // if not
          this.sessionService.set(COUNTRY_COOKIE, e.country_id)
          window.location.href = `/${e['cs.lang']}/dashboard`
          
          this._dialogRef.close(e)
        })
    } else {

      // set service 
      this._dialogRef.close(e)
    }

    // this._store.dispatch(new SetCountry(e.country))
    //this._store.dispatch(new SetLang(e.country))

    // if connected patch user

    // redirect if necessary

    // else define cookie and redirect if necessary

   
  }

}
