
import { CountryMapper, Kind, Mapper } from "yummypets-js-core";
import { UserEntity } from "./user.entity";
import { ConsumerMapper } from "./data-consumer.mapper";
import { AddressMapper } from "./address.mapper";
import { User } from "../domain/user.model";

export class UserMapper implements Mapper<UserEntity, User> {

  mapFromEntity(json: UserEntity): User {

    // eslint-disable-next-line prefer-const
    let user = <User>{}

    try {
  
      user.id = json.id
      user.kind = Kind.USER // 18
      
      if(json.email) user.email = json.email
      if(json.lastname) user.lastname = json.lastname
      if(json.firstname) user.firstname = json.firstname
      if(json.origin) user.origin = json.origin
      if(json.pseudo) user.pseudo = json.pseudo
      if(json.lang) user.lang = json.lang
      if(json.sexe) user.sexe = json.sexe
      if(json.date_born) user.dateBorn = json.date_born
      if(json.default_pet_id) user.defaultPetId = json.default_pet_id
      if(json.country_id) user.countryId = json.country_id

      // user.location = new AddressMapper().mapFromEntity({
      //   city_id: json.city_id,
      //   country_id: json.country_id,
      //   country_iso: json.country_iso,
      //   country_lib: json.country_lib,
      //   address: json.address,
      //   zip_code: json.zip_code
      // })


    } catch (error) {
      console.warn(`Error on ${this.constructor.name}'s mapFromEntity function : `, error)
    }

    return user
  }

  mapToEntity(data: User): UserEntity {

    let user = data as unknown as UserEntity

    try {
     
      user.date_born = data.dateBorn

      // csData?
  
    } catch(error) {
      console.warn(`Error on ${this.constructor.name}'s mapToEntity function : `, error)
    }

    return user
  
  }

}

