import { Injectable, inject } from "@angular/core";
import { Meta, Title } from "@angular/platform-browser";

@Injectable({
    providedIn: 'root',
  })
  export class SeoService {

    /**
     * https://github.com/samvloeberghs/kwerri-oss/blob/main/libs/ngx-seo/src/lib/seo-social-share/seo-social-share.service.ts
     */

    meta = inject(Meta)
    title = inject(Title)

    setTitle(title?: string) {
        if (title && title.length) {

            this.title.setTitle(title);
            this.meta.updateTag({ name: 'title', content: title })
            this.meta.updateTag({ name: 'twitter:title', content: title })
            this.meta.updateTag({ name: 'twitter:image:alt', content: title })
            this.meta.updateTag({ property: 'og:image:alt', content: title })
            this.meta.updateTag({ property: 'og:title', content: title })
            this.meta.updateTag({ name: 'title', content: title })
            this.meta.updateTag({ itemprop: 'name', content: title }, `itemprop='name'`)
        } else {
            
            this.title.setTitle('Explorer')
            this.meta.removeTag(`name='twitter:title'`)
            this.meta.removeTag(`name='twitter:image:alt'`)
            this.meta.removeTag(`property='og:image:alt'`)
            this.meta.removeTag(`property='og:title'`)
            this.meta.removeTag(`name='title'`)
            this.meta.removeTag(`itemprop='name'`)
        }
    }

    setDescription(description?: string) {
        if (description && description.length) {
            this.meta.updateTag({ name: 'twitter:description', content: description })
            this.meta.updateTag({ property: 'og:description', content: description })
            this.meta.updateTag({ name: 'description', content: description })
            this.meta.updateTag({ itemprop: 'description', content: description }, `itemprop='description'`)
        } else {
            this.meta.removeTag(`name='twitter:description'`)
            this.meta.removeTag(`property='og:description'`)
            this.meta.removeTag(`name='description'`)
            this.meta.removeTag(`itemprop='description'`)
        }
    }

    setImage(image: string, options?: any) { // define interface
        if (image && image.length) {

            this.meta.updateTag({ name: 'twitter:image', content: image })
            this.meta.updateTag({ itemprop: 'image', content: image }, `itemprop='image'`)
            this.meta.updateTag({ property: 'og:image', content: image })

            if (options && options.alt) {
                this.meta.updateTag({ property: 'og:image:alt', content: options.alt })
                this.meta.updateTag({ property: 'twitter:image:alt', content: options.alt })
            } else {
                this.meta.removeTag(`property='og:image:alt'`)
                this.meta.removeTag(`property='twitter:image:alt'`)
            }

        } else {
            this.meta.removeTag(`name='twitter:image'`)
            this.meta.removeTag(`property='twitter:image:alt'`)
            this.meta.removeTag(`property='og:image'`)
            this.meta.removeTag(`property='og:image:alt'`)
            this.meta.removeTag(`itemprop='image'`)
        }
    }


  }