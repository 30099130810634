<ul class="board__nav">
    <li>
        <a [routerLink]="'/board/edit-user'" routerLinkActive="active" class="btn btn--sp btn--auth">
            <i class="icon i--profile "></i>
            <span i18n="@@board.menu.user-edit">Edit my account</span>
        </a>
    </li>
    <li>
        <a [routerLink]="'/board/edit-password'" routerLinkActive="active" class="btn btn--sp btn--auth">
            <i class="icon i--password "></i>
            <span i18n="@@board.menu.user-password">Edit password</span>
        </a>
    </li>
    <li>
        <a [routerLink]="'/board/pets'" routerLinkActive="active" class="btn  btn--sp btn--auth">
            <i class="icon i--dog"></i>
            <span i18n="@@board.menu.pets">My pets</span>
        </a>
    </li>
    <li>
        <a (click)="logout()" routerLinkActive="active" class="btn btn--sp btn--auth">
            <i class="icon i--logout"></i>
            <span i18n="@@board.menu.logout">Log out</span>
        </a>
    </li>
</ul>

@if(isMobile) {
    <ul class="board__nav--mobile">
        <li><a routerLink="/legal" routerLinkActive="active" i18n="@@global.link.term-of-use">Term of use</a></li>
        <li><a routerLink="/privacy" routerLinkActive="active" i18n="@@global.link.privacy">Privacy policy</a></li>
        <li><a routerLink="/cookies" routerLinkActive="active" i18n="@@global.link.cookie">Cookie policy</a></li>
    </ul>
}
