import { Injectable } from '@angular/core';
import { TypesNotification } from './notify.utils';

@Injectable({
    providedIn: 'root'
})
export class NotifyConfig {
    limit:     number  = 6;
    showClose: boolean = true;
    timeout:   number  = 4000;
}
