import { Injectable, EventEmitter } from '@angular/core';
import { TypesNotification, NotifyItem, NotifyParams, NotifyEventType, NotifyEvent, isFunction, notifyTypeToString } from './notify.utils';
import { NotifyConfig } from './notify.config';
import { Observable, BehaviorSubject } from 'rxjs';

export function notifyServiceFactory(config: NotifyConfig): NotifyService  {
    return new NotifyService(config)
}
@Injectable({
  providedIn: 'root'
})
export class NotifyService {

  uniqueID: number = 0
  // private notifyEventSource: Subject<NotifyEvent> = new Subject<NotifyEvent>()
  private notifyEventSource: BehaviorSubject<any> = new BehaviorSubject<any>({})
  public notifyEvents: Observable<NotifyEvent> = this.notifyEventSource.asObservable()

  constructor(private notifyConfig: NotifyConfig) {}

  private add(notif: NotifyParams|string, type: TypesNotification ) {
    let notifParams: NotifyParams

    this.uniqueID++

    notifParams = <NotifyParams>notif

    // Convert string params
    if ( typeof notif === 'string' && notif !== '' ) {
      notifParams = <NotifyParams> {
        message: notif
      }
    }

    const notification: NotifyItem = <NotifyItem> {
      id:        this.uniqueID,
      title:     notifParams.title || '',
      class:     notifParams.class || '',
      resource:  notifParams.resource || undefined,
      type:      notifyTypeToString(type),
      message:   notifParams.message || '',
      showClose: notifParams.showClose || this.notifyConfig.showClose,
      timeout:   notifParams.timeout || this.notifyConfig.timeout,
      onAdd    : notifParams.onAdd && isFunction(notifParams.onAdd) ? notifParams.onAdd : null,
      onRemove : notifParams.onRemove && isFunction(notifParams.onRemove) ? notifParams.onRemove : null
    }

    this.notifyEventSource.next( new NotifyEvent(NotifyEventType.ADD, notification) )

    if (notifParams.onAdd) {
       notifParams.onAdd.call(this, notification)
    }

  }

  success(notif:  NotifyParams|string): void {
    this.add(notif, TypesNotification.SUCCESS)
  }

  error(notif:  NotifyParams|string): void {
    this.add(notif, TypesNotification.ERROR)
  }

  warning(notif:  NotifyParams|string): void {
    this.add(notif, TypesNotification.WARNING)
  }

  info(notif:  NotifyParams|string): void {
    this.add(notif, TypesNotification.INFO)
  }

  reward(notif:  NotifyParams|string): void {
    this.add(notif, TypesNotification.REWARD)
  }

  notification(notif:  NotifyParams) {
    this.add(notif, TypesNotification.NOTIFICATION)

  }

  auth() {

    const notify = {
      title: 'Message to define',
      message: '<a href="/login">login cta</a>'
    }
    this.add( notify , TypesNotification.AUTH)
  }

  purge(id: number, notif: any) {

     this.notifyEventSource.next( new NotifyEvent(NotifyEventType.PURGE, id) )
  }

  purgeAll() {

     this.notifyEventSource.next( new NotifyEvent(NotifyEventType.PURGE_ALL) )
  }

}
