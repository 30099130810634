import { EnvironmentProviders, Provider, importProvidersFrom, makeEnvironmentProviders } from "@angular/core";
import { AUTH_CONFIG_TOKEN, AuthConfig } from "./auth.config";
import { DefaultSessionService } from "./services/session.service";
import { DefaultStorageService } from "./services/storage.service";
import { DefaultTokenInterceptor } from "./interceptors/token.interceptor";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptors, withInterceptorsFromDi } from "@angular/common/http";
import { AuthIRepository } from "./data/auth.repository.api";
import { AuthFacade } from "./+state/auth/auth.facade";
import { provideEffects } from "@ngrx/effects";
import { AuthEffects } from "./+state/auth/auth.effects";
import { provideState, provideStore } from "@ngrx/store";
import * as fromAuth from './+state/auth/auth.reducer'
import * as fromPets from './+state/pets/pets.reducer'

import { PetIRepository } from "./data/pet-profile.repository.api";
import { PetsEffects } from "./+state/pets/pets.effects";
import { StoreDevtoolsModule, provideStoreDevtools } from '@ngrx/store-devtools'
import { PetsFacade } from "./+state/pets/pets.facade";
import { ACLService } from "./services/acl.service";
import { ConsumerIRepository } from "./data/consumer.repository";
import { ExpirationService } from "./services/expiration.service";


export const provideAuthModule = (config: AuthConfig) => {

    /**
     * Factory regarding environment -> dynamic variables
     */
    const provider: Provider = [

        { provide: AUTH_CONFIG_TOKEN, useValue: config },
        { provide: ExpirationService, useClass: ExpirationService }, 
        { provide: DefaultSessionService, useClass: DefaultSessionService }, 
        { provide: DefaultStorageService, useClass: DefaultStorageService },
        {
            provide: HTTP_INTERCEPTORS, 
            useClass: DefaultTokenInterceptor, 
            multi: true 
        },
        AuthIRepository,
        PetIRepository,
        ConsumerIRepository,
        ACLService,
        AuthFacade,
        PetsFacade,
        provideHttpClient(withInterceptors([])),
        provideEffects(AuthEffects, PetsEffects),
        provideStore(),
        provideState(
            fromAuth.AUTH_FEATURE_KEY,
            fromAuth.authReducer,
        ),
        provideState(
            fromPets.PETS_FEATURE_KEY,
            fromPets.petsReducer,
        ),
        importProvidersFrom(StoreDevtoolsModule.instrument()),
        provideStoreDevtools({ maxAge: 25 }),
    ];

    return makeEnvironmentProviders(provider);
        
  }