<!-- if not authentactiad and loading display spiner-->
@if(!isAuthenticated() && isLoading()) {
    <mat-spinner diameter="20"></mat-spinner>
} @else {
    <div class="navbar__auth" [class.authenticated]="isAuthenticated()">
        
        <ng-container [ngSwitch]="isAuthenticated()" >
    
            <ng-container *ngSwitchCase="true">
                
                <div class="navbar__user">
                     <!-- Role : {{currentRole()}} -->
                    <span [matTooltip]="tradEditUser" class="navbar-user__name" routerLink="/board/edit-user" i18n="@@nav.hello">Hello {{ profile()?.firstname }}</span>
                    @if(currentPet()) {
                        <div class="navbar-user__pet d-flex vcenter">
                        
                                <span class="mr-1" i18n="@@auth.logged-with">Logged with</span>
                                <span [matTooltip]="tradEditPet" class="mr-2" [routerLink]="['board', 'pets',currentPet()?.id, 'edit']">{{ currentPet()?.pseudo }} </span>
                            
                            <!-- If pet > 1 --> 
                            <!-- <button class="btn btn--icon-rounded mr-2" type="button" routerLink="/board" i18n-matTooltip matTooltip="Edit Profile">
                                <i class="icon i--sm i--profile i--white"></i>
                            </button> -->
                            <!-- @if(currentPet()) {
                                <button class="btn btn--icon-rounded" type="button" click="switchPet()" i18n-matTooltip matTooltip="Switch Pet">
                                    <i class="icon i--sm i--picto-dog i--white"></i>
                                </button>
                            } -->
                        </div>  
                    }
                </div>
    
                <div class="mobile__links mt-10">
                    <ex-board-nav></ex-board-nav>
                </div>
                
            </ng-container>
    
            <ng-container *ngSwitchDefault>
            
                <div class="navbar__cta">
                    <a [routerLink]="'/login'" 
                        class="btn btn--transparent-on-light btn--sm"
                        i18n="@@global.btn.login">Sign in</a>
    
                    <a [routerLink]="'/signup'" 
                        class="btn btn--primary btn--sm"
                        i18n="@@global.btn.join">
                        Join Explorer
                    </a>
                </div>
    
            </ng-container>
    
        </ng-container>
    
    </div>
}
