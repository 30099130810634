
export enum TypesNotification {
  'ERROR', 'WARNING', 'INFO', 'SUCCESS', 'REWARD', 'AUTH', 'NOTIFICATION'
}

export enum NotifyEventType {
  'ADD', 'PURGE', 'PURGE_ALL'
}

export class NotifyParams {
  message?:    string|number
  title?:     string
  resource?:  any
  showClose?: boolean
  timeout?:   number
  class?:      string
  onAdd?:     Function
  onRemove?:   Function
}

export class NotifyItem  {
  id!:         number
  type!:       string
  message?:    string|number
  title?:     string
  resource?:  any
  showClose?: boolean
  class?:     string
  timeout?:   number
  onAdd?:     Function
  onRemove?:   Function
}
  
export class NotifyEvent {

  constructor(public type:  NotifyEventType, public value?: any){}
}
  
export function isFunction(value: any):boolean{

  return typeof value === "function";
}

export function notifyTypeToString(type:TypesNotification):string {

  switch(type){
    case TypesNotification.SUCCESS:
      return 'success';
    case TypesNotification.ERROR:
      return 'error';
    case TypesNotification.WARNING:
      return 'warning';
    case TypesNotification.INFO:
      return 'info';
    case TypesNotification.REWARD:
      return 'reward';
    case TypesNotification.AUTH:
      return 'auth';
    case TypesNotification.NOTIFICATION:
      return 'notification'
    default: 
      return 'success';
  }
}