import { Injectable, inject } from "@angular/core"
import { skipWhile, take, tap } from "rxjs"
import { AuthFacade } from "../+state/auth/auth.facade"


@Injectable({ providedIn: 'root' })
export class UserLoadedGuard  {

    readonly authFacade = inject(AuthFacade)

    canActivate() {

        return this.authFacade.loaded$.pipe(
            skipWhile((value) => value === false),
            take(1)
        )
    }
}