import { inject } from "@angular/core"
import { ActivatedRouteSnapshot, ResolveFn, Router, RouterStateSnapshot } from "@angular/router"
import { EMPTY, catchError, map, throwError } from "rxjs"
import { PollRepositoryApi } from "src/app/data/api/poll-repository.api"

export const questionResolver: ResolveFn<any> =
(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {

    const router = inject(Router)
    const pollApi = inject(PollRepositoryApi)

    return pollApi.fetchToday().pipe(map((result:any) => {

        if(result) {
            return result
        }

        // Add a return statement here
        return EMPTY;
    }))
        // catchError(err => {
        //     router.navigate(['/404'])
        //     return throwError(err)
        // }))
    // )
}