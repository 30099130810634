import { EntityState, EntityAdapter, createEntityAdapter } from '@ngrx/entity'
import { createReducer, on, Action } from '@ngrx/store'

import * as PetsActions from './pets.actions'
import { Pet } from '../../domain/pet.model'
import { isConsumerFullfilled } from '../../domain/user-consumer.utils'
import { isPetDataFullfilled } from '../../domain/pet.utils'

export const PETS_FEATURE_KEY = 'pets'

export interface PetsState {
  currentPet: Pet | null,
  pets: Pet[],
  loadedPet: boolean,
  loadedPets: boolean,
  isLoadingPet: boolean,
  isLoadingPets: boolean,
  isCsDataFullfilled: boolean,
  isCsDataExpired: boolean,
  isDataFullfilled: boolean // classic (date_born)
  error?: string | null
}

export interface PetsPartialState {
  readonly [PETS_FEATURE_KEY]: PetsState
}

export const initialPetsState: PetsState = {
  currentPet: null,
  loadedPet: false,
  loadedPets: false,
  isLoadingPet: false,
  isLoadingPets: false,
  isCsDataFullfilled: false,
  isCsDataExpired: false,
  isDataFullfilled:false,
  error: null,
  pets: []
}

const reducer = createReducer(
  initialPetsState,

  /**
   * - CURRENT PET
   */
  on(PetsActions.loadPet, (state) => ({
    ...state,
    isLoadingPet: true,
    loadedPet: false,
    error: null
  })),

  on(PetsActions.loadPetSuccess, (state, { pet }) => ({
    ...state,
    isLoadingPet: false,
    isDataFullfilled: isPetDataFullfilled(pet),
    isCsDataFullfilled: pet.csData ? isConsumerFullfilled(pet.csData.collection) : false,
    isCsDataExpired: pet.csData ? pet.csData.isExpired : false,
    loadedPet: true,
    error: null,
    currentPet: pet
  })),

  on(PetsActions.loadPetFailure, (state, { error }) => ({
    ...state,
    isLoadingPet: false,
    error
  })),

  on(PetsActions.loadNoPet, (state) => ({
    ...state, 
    loadedPet: true
  })),

  /**
   * - PETS
   */
  on(PetsActions.loadPets, (state) => ({
    ...state,
    isLoadingPet: true,
  })),

  on(PetsActions.loadPetsSuccess, (state, { pets }) => ({
    ...state,
    isLoadingPet: false,
    pets
  })),

  /**
   * - ADD PET
   */
  on(PetsActions.addPet, (state) => ({
    ...state,
    isLoadingPet: true,
    loaded: false,
    error: null
  })),

  on(PetsActions.addPetSuccess, (state, { pet }) => ({
    ...state,
    isLoadingPet: false,
    isDataFullfilled: true,
    isCsDataFullfilled: pet.csData ? isConsumerFullfilled(pet.csData.collection) : false,
    loadedPet: true,
    error: null,
    currentPet: pet
  })),

  on(PetsActions.addPetFailure, (state, { error }) => ({
    ...state,
    isLoadingPet: false,
    loaded: false,
    error
  })),

  /**
   * - EDIT PET
   */
  on(PetsActions.editPet, (state) => ({
    ...state,
    isLoadingPet: true,
    error: null
  })),

  on(PetsActions.editPetSuccess, (state, { pet }) => ({
    ...state,
    isDataFullfilled: true,
    isLoadingPet: false,
    error: null,
    // currentPet: pet - Edit pet success call load pet
  })),

  on(PetsActions.editPetFailure, (state, { error }) => ({
    ...state,
    isLoadingPet: true,
    error
  })),

  on(PetsActions.purge, () => ({
    ...initialPetsState
  })),

  /**
   * ---------------------------------------------------
   * - OTHERS
   * ---------------------------------------------------
   */
    on(PetsActions.resetPetError, (state) => ({
      ...state,
      error: null
    })),

)

export function petsReducer(state: PetsState | undefined, action: Action) {
  return reducer(state, action) 
}
